// Function to set a cookie
export function setCookie(name, value, days) {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + days);
    const cookie = `${name}=${encodeURIComponent(value)};expires=${expirationDate.toUTCString()};path=/`;
    document.cookie = cookie;
}

// Function to get a cookie by name
export function getCookie(name) {
    const cookies = document.cookie.split("; ");
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].split("=");
        const cookieName = decodeURIComponent(cookie[0]);
        const cookieValue = decodeURIComponent(cookie[1]);
        if (cookieName === name) {
            return cookieValue;
        }
    }
    return "";
}

// Function to save login credentials
export function saveLoginCredentials(username, password, rememberMe) {
    if (rememberMe) {
        setCookie("username", username, 30); // Set cookie for username with 30 days expiration
        setCookie("password", password, 30); // Set cookie for password with 30 days expiration
    } else {
        setCookie("username", "", -1); // Clear the username cookie
        setCookie("password", "", -1); // Clear the password cookie
    }
}

// Function to load login credentials
export function loadLoginCredentials() {
    const username = getCookie("username");
    const password = getCookie("password");
    return { username, password };
}
