import { LINK_STATUS } from "../constants";

export const linkActions = {
  LOAD_FLP_LINK_STATUS: "LOAD_FLP_LINK_STATUS",
  SET_FLP_LINK_STATUS: "SET_FLP_LINK_STATUS",
  // ERROR_FLP_LINK_STATUS: "ERROR_FLP_LINK_STATUS",
};

const initState = { isLoading: false, status: LINK_STATUS.UNKNOWN };

const reducer = (state = initState, action) => {
  switch (action.type) {
    case linkActions.LOAD_FLP_LINK_STATUS: {
      return { isLoading: true, status: LINK_STATUS.UNKNOWN };
    }
    // case linkActions.ERROR_FLP_PATIENT_DATA: {
    //   return initState;
    // }
    case linkActions.SET_FLP_LINK_STATUS: {
      return { ...state, isLoading: false, ...action.payload };
    }
    default:
      return state;
  }
};

export default reducer;
