// reactstrap components
import { useSelector } from "react-redux";
import { Card, CardHeader, CardBody, Container, Row, Col, Form, FormGroup, Input } from "reactstrap";
import {
  ReferralsListTable,
  TotalReferrals,
} from "../components/Headers/InfoCards.jsx";
import Referrals from "../components/Referrals/Referrals.js";

const ReferralsPage = () => {
  const { referredUser } = useSelector((state) => ({
    referredUser: state.refs.referredUser
  }));
  
  return (
    <>
      {/* Page content */}
      <Container fluid>
        <Row>
          <Col xl="8" className="d-flex mt-2 mt-md-6">
            <Card className="bg-secondary w-100 border shadow mt-4">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-start">
                  <Col xs="8">
                    <h3 className="mb-0">Refer & Earn</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    Referred by someone ?
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-referalCode"
                          >
                            Referral Code
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-referalCode"
                            placeholder="--"
                            disabled
                            type="text"
                            name="referalCode"
                            value={referredUser?.referralCode || ""}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                </Form>
                <Referrals />
              </CardBody>
            </Card>
          </Col>
          <Col xl="4" className="mt-4 mt-md-6">
            <div className="mt-4">
              <TotalReferrals />
            </div>
            <div className="mt-4">
              <ReferralsListTable />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ReferralsPage;
