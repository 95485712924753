import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  EmailShareButton,
  WhatsappIcon,
  FacebookMessengerShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  FacebookMessengerIcon,
  TwitterIcon,
  LinkedinIcon,
  EmailIcon,
} from "react-share";
import { Button, Col, FormGroup, Label, Row, Spinner } from "reactstrap";
import { getReferralConfig } from "../../actions/referrals";
import { useDispatch, useSelector } from "react-redux";
import { refActions } from "../../reducers/refReducer";

const Referrals = () => {
  const { referralCode, isLoading } = useSelector(state => ({
    referralCode: state.refs.referralCode,
    isLoading: state.refs.isLoading
  }))
  // const [code, setCode] = useState();
  const [copiedType, setCopiedType] = useState("");
  const dispatch = useDispatch();

  const getConfig = useCallback(async () => {
    try {
      dispatch({ type: refActions.GET_REF_CODE });
      const config = await getReferralConfig();
      // setCode(config);
      dispatch({ type: refActions.GET_REF_CODE_SUCCESS, payload: config })
    } catch (error) {
      console.log("Failed to show your Referral code.", error);
      dispatch({ type: refActions.GET_REF_CODE_FAILED, payload: 'Failed to load config.' });
    }
  }, [dispatch]);

  useEffect(() => {
    getConfig();
  }, [getConfig]);

  const referralLink = useMemo(
    () => `${window.origin}/auth/register/${referralCode}`,
    [referralCode]
  );

  const clearCopiedTag = () => {
    setTimeout(() => {
      setCopiedType("");
    }, 1000);
  };

  const handleCopyClick = (text, type) => {
    navigator.clipboard.writeText(text).then(
      () => {
        setCopiedType(type);
        clearCopiedTag();
        console.log("Text copied to clipboard");
      },
      (error) => {
        console.error("Failed to copy text: ", error);
      }
    );
  };

  const title = `Use my link to securely donate your health data for medical research in 5 minutes and earn royalties for the rest of your life!!!`;
  const shareUrl = `https://healthxchange.us/auth/register/${referralCode}`;

  if (isLoading) {
    return (
      <Spinner
        as="span"
        animation="border"
        role="status"
        size="sm"
        className="mr-2"
      />
    );
  }

  return (
    <div>
      <h6 className="heading-small text-muted mb-4">You can refer your friends and family members using below code / link</h6>
      <div className="pl-lg-4">
        <Row>
          <Col lg="12">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-username">
                Your Referral Code
              </label>

              <Label
                className="strong token inserted text-primary d-flex justify-content-between align-items-center"
                style={{ border: "3px dashed var(--info)", borderRadius: 8 }}
              >
                <Label className="m-2" onClick={(e) => e.preventDefault()}>
                  {referralCode}
                </Label>
                <Button
                  className={`btn-sm float-right align-center mr-2 ${
                    copiedType === "code" && "bg-success text-white"
                  }`}
                  onClick={() => handleCopyClick(referralCode, "code")}
                >
                  <i
                    className={`fa ${
                      copiedType === "code" ? "fa-check" : "fa-copy"
                    }`}
                  />
                </Button>
              </Label>
            </FormGroup>
            <FormGroup>
              <label className="form-control-label" htmlFor="input-username">
                Referral Link
              </label>
              <Label
                className="strong token inserted text-primary d-flex justify-content-between align-items-center"
                style={{ border: "3px dashed var(--info)", borderRadius: 8 }}
              >
                <Label className="m-2" onClick={(e) => e.preventDefault()}>
                  {referralLink}
                </Label>
                <Button
                  className={`btn-sm float-right align-center mr-2 ${
                    copiedType === "link" && "bg-success text-white"
                  }`}
                  onClick={() => handleCopyClick(referralLink, "link")}
                >
                  <i
                    className={`fa ${
                      copiedType === "link" ? "fa-check" : "fa-copy"
                    }`}
                  />
                </Button>
              </Label>
            </FormGroup>
            <FormGroup className="d-flex" style={{ gap: 5 }}>
              <WhatsappShareButton url={shareUrl} title={title} separator=":: ">
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>
              <FacebookMessengerShareButton
                url={shareUrl}
                appId="521270401588372"
              >
                <FacebookMessengerIcon size={32} round />
              </FacebookMessengerShareButton>
              <TwitterShareButton url={shareUrl} title={title}>
                <TwitterIcon size={32} round />
              </TwitterShareButton>
              <LinkedinShareButton url={shareUrl}>
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>
              <EmailShareButton
                url={shareUrl}
                subject="Health XChange - earn rewards for your health data."
                body={title}
                separator="
"
              >
                <EmailIcon size={32} round />
              </EmailShareButton>
            </FormGroup>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Referrals;
