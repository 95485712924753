import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getExistingPatientData,
  getFlpLinkStatus,
  getPatientData,
} from "../actions/flexPa";
import { flexPaActions } from "../reducers/flexpaReducer";
import usePreConfig from "./usePreConfig";
import { linkActions } from "../reducers/linkStatusReducer";

const useFlexpa = () => {
  const dispatch = useDispatch();
  const { preConfig: { uiFlexpaPublishableKey } = {} } = usePreConfig();
  const { flexpaData, flexpaLink } = useSelector((state) => ({
    flexpaData: state.flexpa,
    flexpaLink: state.flexpaLink,
  }));

  const refreshLinkStatus = useCallback(async () => {
    dispatch({ type: linkActions.LOAD_FLP_LINK_STATUS });
    const response = await getFlpLinkStatus();
    dispatch({
      type: linkActions.SET_FLP_LINK_STATUS,
      payload: {
        status: response?.status,
        links: response?.links || null,
      },
    });
  }, [dispatch]);

  const syncPatientRecords = useCallback(
    async (publicToken) => {
      if (publicToken) {
        dispatch({
          type: flexPaActions.LOAD_FLP_PATIENT_DATA,
          payload: "Pulling your data from your Health provider.",
        });
        const myData = await getPatientData(publicToken);
        dispatch({
          type: flexPaActions.SET_FLP_PATIENT_DATA,
          payload: myData || {},
        });
        refreshLinkStatus();
      }
    },
    [dispatch, refreshLinkStatus]
  );

  useEffect(() => {
    if (!uiFlexpaPublishableKey) {
      console.warn("Flexpa configuration not loaded. Please refresh the page.");
      return;
    }
    console.log("Initializing flexpa.");
    window.FlexpaLink?.create?.({
      publishableKey: uiFlexpaPublishableKey,
      onLoad: (...args) => {
        console.log("LOAD ARGS: ", args);
      },
      onSuccess: (publicToken, ...args) => {
        console.log("ALL ARGS:", args);
        console.log(
          "SRI: PUBLIC TOKEN AFTER LINKED TO SOME PROVIDER",
          publicToken
        );
        syncPatientRecords(publicToken);
      },
    });
  }, [uiFlexpaPublishableKey, syncPatientRecords]);

  const openFlexpa = () => {
    console.log("Flexpa", window.FlexpaLink);
    window.FlexpaLink?.open?.();
  };

  const getExistingData = useCallback(async () => {
    dispatch({
      type: flexPaActions.LOAD_FLP_PATIENT_DATA,
      payload: "Checking your data",
    });
    const myData = await getExistingPatientData();
    dispatch({
      type: flexPaActions.SET_FLP_PATIENT_DATA,
      payload: myData || null,
    });
  }, [dispatch]);

  useEffect(() => {
    if (!flexpaData.data) {
      getExistingData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    openFlexpa,
    flexpaLink: flexpaLink,
    isLoading: flexpaData.isLoading,
    flexpaData: flexpaData.data,
    syncPatientRecords,
    refreshLinkStatus,
  };
};

export default useFlexpa;
