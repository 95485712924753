import { Card, CardBody, CardHeader } from "reactstrap";
import { FLEXPA_LINKS_EXCEEDED_LIMIT } from "../../utils/config";

const getIcon = (type) => {
  switch (type) {
    case "success":
      return "fa-check-circle";
    case "danger":
      return "fa-exclamation-circle";
    default:
      return "fa-bug";
  }
};

const RegistrationSuccessModal = ({ type, title, message, isSocialSignIn }) => {
  return (
    <Card className="bg-success text-white shadow border-0">
      <CardHeader className="bg-transparent">
        <div className="text-center">
          <h2 className="text-white text-lg my-0">{title}</h2>
        </div>
      </CardHeader>
      <CardBody className="px-lg-3 py-lg-3">
        <div className="modal-body">
          <div className="py-3 text-center">
            <i className={`fa ${getIcon(type)} ni-3x`} />
            <p className="my-4" style={{ fontWeight: "normal" }}>
              {message}
            </p>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export const RegistrationsClosedCard = () => {
  return (
    <Card className="bg-dark text-white shadow border-0">
      <CardHeader className="bg-transparent">
        <div className="text-center">
          <h2 className="text-white text-lg my-0">
            REGISTRATIONS CURRENTLY CLOSED
          </h2>
        </div>
      </CardHeader>
      <CardBody className="px-lg-3 py-lg-3">
        <div className="modal-body py-0">
          <div className="py-3 text-center">
            <i className={`fa fa-info ni-3x`} />
            <p className="my-4 text-center" style={{ fontWeight: "normal" }}>
              {FLEXPA_LINKS_EXCEEDED_LIMIT}
            </p>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default RegistrationSuccessModal;
