import React, { lazy } from "react";
// reactstrap components
import { Container, Row } from "reactstrap";
import { Styles } from "../components/Homepage/styles/styles";

// core components
import AuthNavbar from "../components/Navbars/AuthNavbar.js";
import AuthFooter from "../components/Footers/AuthFooter.js";

import IntroContent from "../components/Homepage/content/IntroContent.json";
import WhitePaperContent from "../components/Homepage/content/WhitePaper.json";
import { FaqContent } from "../components/Homepage/content/FaqContent";
import { useLocation } from "react-router-dom";

const ContentBlock = lazy(() =>
  import("../components/Homepage/components/ContentBlock/index.jsx")
);
const MiddleBlock = lazy(() =>
  import("../components/Homepage/components/MiddleBlock/index.jsx")
);

const Public = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.body.classList.add("bg-white");
    return () => {
      document.body.classList.remove("bg-white");
    };
  }, []);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  return (
    <>
      <div className="main-content" ref={mainContent}>
        <Styles />
        <AuthNavbar />
        <Container>
          <ContentBlock
            type="right"
            title={IntroContent.title}
            content={IntroContent.text}
            button={IntroContent.button}
            icon="intro.svg"
            id="intro"
          />
        </Container>
        <div className="header bg-gradient-info py-7 py-lg-8">
          <div className="separator separator-top zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                points="2560 0 2560 600 400 0"
                className="fill-white"
              ></polygon>
            </svg>
          </div>
          <Container className="mt--8 pb-5">
            <Row className="justify-content-center">{props.component}</Row>
          </Container>
          <div className="separator separator-bottom zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon className="fill-white" points="2560 0 2560 100 0 100" />
            </svg>
          </div>
        </div>
        <Container>
          <ContentBlock
            type="right"
            title={WhitePaperContent.title}
            content={WhitePaperContent.text}
            button={WhitePaperContent.button}
            icon="cdd368_a0b534c4b24a4b849d9bfa1a178150a6~mv2.webp"
            id="whitepaper"
          />
          <MiddleBlock
            title={FaqContent.title}
            content={FaqContent.text}
            accordion={FaqContent.accordion}
          />
        </Container>
        {/* Page content */}
      </div>
      <AuthFooter />
    </>
  );
};

export default Public;
