import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

const AnonymousComponent = ({ auth, children }) => {
  const history = useHistory();
  const { username, email } = auth || {};
  if (username && email) {
    return history.replace("/admin/flexpa");
    // if (isHealthSystemConnected) {
    //   return history.replace("/admin/index");
    // } else {
    // }
  }
  return <React.Fragment>{children}</React.Fragment>;
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(AnonymousComponent);
