export const FaqContent = {
  "title": "FAQ",
  "accordion": [
    {
      "title": "What do `royalty points` refer to?",
      "type": "markdown",
      "content": `Royalty points are a measure of your activity and engagement on our platform. When you contribute your health data and interact with personalized messages and ads, you accumulate these points.

Currently, these royalty points track and represent your contributions and interactions. However, in the future, we plan to distribute digital tokens. This token will represent a tangible share of the revenues we generate from our partnerships with pharmaceutical companies and advertisers.

In other words, your royalty points today will be a factor in determining how many tokens you will receive once we launch our token. It's our way of ensuring you benefit directly from the valuable contributions you make to our community.`
    },
    {
      "title": "How are you getting my data from insurance companies and hospitals?",
      "type": "markdown",
      "content": `When you join our platform, you will be asked to authorize access to your health data from your insurance company. This is done through secure APIs (Application Programming Interfaces) which are specifically designed to transfer data safely and securely.
      
Regarding hospital data, we comply with all applicable laws and regulations concerning health data access and exchange. We work in collaboration with your healthcare providers who use electronic health record systems capable of securely sharing your anonymized health information.
      
We ensure that all data is anonymized and encrypted, maintaining the highest standards of privacy and data security. Your data is always under your control and you can choose what you share and when you share it.`
    },
    {
      "title": "What is my data being used for?",
      "type": "markdown",
      "content": `Your anonymized health data is utilized in two main ways.
      
Firstly, it contributes to a pool of information that aids in advanced healthcare research. This helps pharmaceutical companies better understand diseases and accelerates the development of new treatments.
      
Secondly, your data helps us to tailor specific messages and advertisements that are most relevant to your health interests and needs. In this process, we share revenue generated from these messages and ads with you, the data owner. This ensures you also benefit from the commercial use of your data.
      
Your data is always anonymized and encrypted to ensure your privacy is preserved.`
    }
  ]
}
