import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/scss/argon-dashboard-react.scss";
import AdminLayout from "./layouts/Admin.js";
import AuthLayout from "./layouts/Auth.js";
import { Provider } from "react-redux";
import configureAppStore from "./reducers/store";
import { preLoadState } from "./utils/preLoadState";
import PrivateComponent from "./components/Wrappers/PrivateComponent";
import AnonymousComponent from "./components/Wrappers/AnonymousComponent";
import { Container, Spinner } from "reactstrap";
import Public from "./layouts/Public";
import HomeLayout from "./layouts/Home";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Home from "./pages/Home";
import SyncData from "./pages/SyncData";
import usePreConfig from "./utils/usePreConfig";
import "./App.css";

const PrivateAdmin = (props) => {
  return (
    <PrivateComponent>
      <AdminLayout {...props} />
    </PrivateComponent>
  );
};

const AnonymousAuth = (props) => {
  return (
    <AnonymousComponent>
      <AuthLayout {...props} />
    </AnonymousComponent>
  );
};

const PublicComponents = (props) => {
  return <Public {...props} />
}

export default function App() {
  const [preLoadedState, setPreLoadedState] = useState(undefined);
  usePreConfig();
  
  useEffect(() => {
    const getPreState = async () => {
      const loginState = await preLoadState();
      setPreLoadedState(loginState);
    };
    getPreState();
  }, []);

  if (!preLoadedState) {
    return (
      <Container className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner
          as="span"
          animation="border"
          role="status"
          size="sm"
        />
      </Container>
    );
  }
  const store = configureAppStore(preLoadedState);
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <Route
            path="/admin"
            render={(props) => <PrivateAdmin {...props} />}
          />
          <Route
            path="/auth"
            render={(props) => <AnonymousAuth {...props} />}
          />
          <Route
            path="/privacy"
            render={(props) => <PublicComponents component={<PrivacyPolicy />} {...props} />}
          />
          <Route
            path="/data-import"
            render={(props) => <SyncData {...props} />}
          />
          <Route
            path="/confirmation/:email/:token"
            render={(props) => <HomeLayout component={<Home intent="confirmation" />} {...props} />}
          />
          <Route
            path="/"
            render={(props) => <HomeLayout component={<Home />} {...props} />}
          />
        </Switch>
      </BrowserRouter>
    </Provider>
  );
}
