export const refActions = {
  GET_REFERRALS: "GET_REFERRALS",
  GET_REFERRALS_FAILED: "GET_REFERRALS_FAILED",
  GET_REFERRALS_SUCCESS: "GET_REFERRALS_SUCCESS",
  GET_REF_CODE: "GET_REF_CODE",
  GET_REF_CODE_FAILED: "GET_REF_CODE_FAILED",
  GET_REF_CODE_SUCCESS: "GET_REF_CODE_SUCCESS",
};

const initState = {
  referralCode: '',
  referredUser: null,
  referrals: [],
  isLoading: false,
  error: "",
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case refActions.GET_REFERRALS: {
      return { ...state, isLoading: true, error: "" };
    }
    case refActions.GET_REFERRALS_FAILED: {
      return { ...state, isLoading: false, error: action.payload };
    }
    case refActions.GET_REFERRALS_SUCCESS: {
      return { ...state, referrals: action.payload, isLoading: false };
    }
    case refActions.GET_REF_CODE: {
      return { ...state, isLoading: true, error: '' };
    }
    case refActions.GET_REF_CODE_FAILED: {
      return { ...state, isLoading: false, error: action.payload };
    }
    case refActions.GET_REF_CODE_SUCCESS: {
      return { ...state, referralCode: action.payload.referralCode, referredUser: action.payload.referredUser, isLoading: false };
    }
    default:
      return state;
  }
};

export default reducer;
