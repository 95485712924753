// reactstrap components
import { useSelector } from "react-redux";
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  UncontrolledCollapse,
  Button,
} from "reactstrap";
import {
  OfferCard,
  RoyalityStakeCard,
  SyncDataModalCard,
} from "../components/Headers/InfoCards.jsx";

const Profile = () => {

  const { authUser } = useSelector((storeState) => ({
    authUser: storeState.auth,
  }));

  return (
    <>
      {/* Page content */}
      <Container fluid>
        <Row>
          <Col xl="8" className="d-flex mt-2 mt-md-6">
            <Card className="bg-secondary w-100 border shadow mt-4">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-start">
                  <Col xs="8">
                    <h3 className="mb-0">My account</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    User information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Username
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            placeholder="Username"
                            disabled
                            type="text"
                            value={authUser.username}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Email address
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            placeholder="user@domain.com"
                            type="email"
                            disabled
                            value={authUser.email}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  <div>
                    <Button
                      tag="a"
                      color="secondary"
                      id="toggler"
                      className="mb-2 btn-sm text-primary text-underscore"
                    >
                    <i className="fa fa-trash" />
                      &nbsp; I want to delete my account
                      &nbsp; <i className="fa fa-chevron-down" />
                    </Button>
                    <UncontrolledCollapse toggler="#toggler">
                      <div className="p-5 mb-4 bg-light border rounded rounded-3">
                        If you wish to delete your account, please email{" "}
                        <a
                          href={`mailto:support@healthxchange.us?subject=Account Deletion Request`}
                        >
                          support@healthxchange.us
                        </a>{" "}
                        with the subject line – “
                        <strong>Account Deletion Request</strong>” and we will
                        permanently delete all of your information within 10
                        business days.
                      </div>
                    </UncontrolledCollapse>
                  </div>
                </Form>
                {/* <Referrals /> */}
              </CardBody>
            </Card>
          </Col>
          <Col xl="4" className="mt-4 mt-md-6">
            <div style={{ height: 150 }} className="mt-4">
              <RoyalityStakeCard />
            </div>
            <div style={{ height: 150 }} className="mt-4">
              <SyncDataModalCard />
            </div>
            <div style={{ height: 150 }} className="mt-4">
              <OfferCard />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Profile;
