import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Spinner,
  Label,
} from "reactstrap";
import apiClient from "../../utils/apiClient";
import { useDispatch, useSelector } from "react-redux";
import { setSyncModalState } from "../../actions/uiActions";

const SyncData = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [oneUpAuth, setOneUpAuth] = useState({});
  const [error, setError] = useState('');
  const { syncModal = false } = useSelector(state => state.ui);
  const dispatch = useDispatch();

  const getNewAccessToken = useCallback(async () => {
    setIsLoading(true);
    setError('');
    try {
      const response = await apiClient({
        method: "post",
        url: "/auth/oneUpToken",
      });
      setOneUpAuth(response.data);
    } catch (error) {
      setError(`Failed to fetch health systems information`);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (syncModal) getNewAccessToken();
  }, [syncModal, getNewAccessToken]);

  const dispatchToggleModal = () => {
    dispatch(setSyncModalState(false));
  }

  return (
    <Modal isOpen={syncModal} toggle={dispatchToggleModal} backdrop="static" keyboard={false} size="md" scrollable={false}>
      <ModalHeader toggle={dispatchToggleModal} />
      <ModalBody><div className="d-flex flex-column justify-content-center align-items-center">
        {
          isLoading ? <>
            <Spinner
              className="mb-4"
              as="span"
              animation="border"
              role="status"
              size="sm"
            />
            <Label>
              Getting available Health systems
            </Label>
          </>
            : error ? <Label className="text-danger">{error}</Label> :
              (oneUpAuth.access_token && (
                <iframe
                  height="400"
                  title="Linking data from health systems"
                  style={{ border: "0px solid #fff" }}
                  src={`https://system-search.1up.health/search?access_token=${oneUpAuth.access_token}`}
                  width="100%"
                />
              ))
        }
      </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={dispatchToggleModal}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default SyncData;
