import { useEffect, useState } from "react";
import { Container, Label, Spinner } from "reactstrap";
import { triggerDataImport } from "../actions/oneUpActions";
import { Redirect } from "react-router-dom";

const SyncData = () => {
  const [importStatus, setImportStatus] = useState(false);

  useEffect(() => {
    const initRemoteAction = async () => {
      const response = await triggerDataImport();
      setImportStatus(response.success);
    };
    initRemoteAction();
  }, []);

  if (importStatus) {
    return <Redirect to="/admin/index" />;
  }

  return (
    <Container style={{ height: "100vh" }}>
      <div className="d-flex justify-content-center align-items-center h-100 flex-column flex-gap-2">
        <Spinner size="lg" />
        <Label className="mt-3 text-muted">Importing data...</Label>
        <Label className="text-muted text-sm">
          Please do not close or press back button
        </Label>
      </div>
    </Container>
  );
};

export default SyncData;
