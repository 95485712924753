// reactstrap components
import { useEffect, useMemo, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  FormFeedback,
  Spinner,
} from "reactstrap";
import { useForm } from "../../utils/useForm";
import {
  checkOpenRegistrations,
  getGoogleUser,
  registerUser,
  signInUser,
} from "../../actions/authActions";
import { connect, useDispatch } from "react-redux";
import LoginWithGoogle from "../../components/GoogleLogin/LoginWithGoogle";
import { appConfig } from "../../utils/config";
import "./FormFeedbackColors.scss";
import { useParams } from "react-router-dom";
import RegistrationSuccessModal, {
  RegistrationsClosedCard,
} from "../../components/Modal/RegistrationSuccess";
import { REGISTRATIONS_STATUS } from "../../constants";

const getPasswordStrength = (password) => {
  const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/;
  if (!pattern.test(password)) {
    return "error";
  }
  // Check additional criteria for moderate and strong passwords
  if (password.length >= 12 && /[!@#$%^&*]/.test(password)) {
    return "success";
  }
  return "info";
};

const Register = () => {
  const { referralCode } = useParams();
  const [newUser, updateNewUser, onFocusOut, resetTo] = useForm({
    refCode: { value: referralCode },
  });
  const [isVerified, setIsVerified] = useState(false);
  const dispatch = useDispatch();
  const [loading, toggleLoading] = useState(false);
  const [validatingSocialSignin, setValidatingSocialSignin] = useState(false);
  const [formFeedback, setFeedback] = useState(undefined);
  const [credential, setCredential] = useState("");
  const [isRegistrationsOpen, setIsRegistrationsOpen] = useState();

  const {
    username = {},
    email = {},
    password = {},
    agree = {},
    dataConsent = {},
    confirmPassword = {},
    refCode = {},
  } = newUser;

  useEffect(() => {
    const fetchState = async () => {
      const status = await checkOpenRegistrations();
      setIsRegistrationsOpen(status);
    };
    fetchState();
  }, []);

  const { fieldFeedback, isFormValid, isSocialUser } = useMemo(() => {
    const {
      username = {},
      email = {},
      password = {},
      confirmPassword = {},
      iss = {},
      refCode = {},
    } = newUser;
    const messages = {};
    let isFormValid = true;
    if (!username.value) {
      messages.username = username.touched ? "Username is required" : "";
      isFormValid = false;
    } else {
      // const usernamePattern = /^[a-zA-Z][a-zA-Z0-9_]{2,}$/; // characters, numbers, underscore, 3 char length
      const isValidFormat = username?.value?.length >= 3; // usernamePattern.test(username.value);
      if (!isValidFormat) {
        messages.username = username.touched
          ? "Username must be of minimum 3 characters"
          : "";
        isFormValid = false;
      }
    }
    if (!email.value) {
      messages.email = email.touched ? "Email is required" : "";
      isFormValid = false;
    }
    if (password.touched) {
      if (!password.value) {
        messages.password = {
          message: "Password is required",
          color: "error",
        };
        isFormValid = false;
      } else {
        const strength = getPasswordStrength(password.value);
        messages.password = {
          message:
            strength === "error"
              ? "Please choose a strong password with at least 1 Uppercase, Lowercase, Special character and a min of 8 characters."
              : strength,
          color: strength,
        };
        if (strength === "error") {
          isFormValid = false;
        }
      }
      if (!confirmPassword.value) {
        messages.confirmPassword = confirmPassword.touched
          ? "Please re-enter the password"
          : "";
        isFormValid = false;
      } else if (password.value !== confirmPassword.value) {
        messages.confirmPassword = confirmPassword.touched
          ? "Passwords do not match"
          : "";
        isFormValid = false;
      }
    }
    if (refCode.value?.length > 0 && refCode.value?.length !== 8) {
      messages.refCode = referralCode
        ? "Invalid referral link"
        : refCode.touched
        ? "Referral code must be 8 characters"
        : "";
      isFormValid = false;
    }
    return { fieldFeedback: messages, isFormValid, isSocialUser: !!iss.value };
  }, [newUser, referralCode]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isVerified) {
      return setFeedback({
        type: "error",
        message: <span>Please complete captcha verification</span>,
      });
    }
    toggleLoading(true);
    setFeedback({});
    const {
      username: { value: username },
      email: { value: email },
      password: { value: password } = {},
      confirmPassword: { value: confirmPassword } = {},
      iss: { value: iss } = {},
      userId: { value: userId } = {},
      picture: { value: picture } = {},
      refCode: { value: refCode } = {},
      agree: { value: agree } = {},
      dataConsent: { value: dataConsent } = {},
    } = newUser;
    try {
      await registerUser({
        username,
        password,
        email,
        confirmPassword,
        iss,
        userId,
        picture,
        referralCode: refCode,
        agree,
        dataConsent,
      });
      setFeedback({
        type: "success",
        title: "You are registered successfully.",
        message: !iss
          ? "Please check your inbox/spam and confirm your account."
          : "Please proceed to signin",
      });
      resetTo({ iss: { value: iss, touched: false } });
      if (iss === "google" && credential) {
        dispatch(signInUser({ credential, iss }));
      }
    } catch (error) {
      setFeedback({ type: "danger", message: error.message });
    }
    toggleLoading(false);
  };

  const handleGoogleRegister = async (credential) => {
    setValidatingSocialSignin(true);
    setCredential(credential);
    try {
      const googleUser = await getGoogleUser(credential);
      if (!googleUser) return;
      const { email, name, userId, picture, iss } = googleUser;
      console.log(`Pre filling form for social signin`);
      resetTo({
        username: { touched: false, value: name },
        email: { touched: false, value: email },
        userId: { touched: false, value: userId },
        picture: { touched: false, value: picture },
        iss: { touched: false, value: iss },
        credential: { touched: false, value: credential },
        refCode: { touched: false, value: referralCode },
      });
    } catch (error) {
      console.log(`Failed to validate user login`);
    } finally {
      setValidatingSocialSignin(false);
    }
  };

  if (!isRegistrationsOpen) {
    return (
      <Col className="col-auto">
          <Spinner
            as="span"
            animation="border"
            role="status"
            size="lg"
            className="mr-2"
          />
      </Col>
    );
  }

  if (isRegistrationsOpen === REGISTRATIONS_STATUS.CLOSED) {
    return (
      <Col lg="6" md="8">
        <RegistrationsClosedCard />
      </Col>
    );
  }

  return (
    <>
      <Col lg="6" md="8">
        {formFeedback?.type === "success" ? (
          <RegistrationSuccessModal
            {...formFeedback}
            isOpen
            isSocialSignIn={newUser?.iss}
          />
        ) : (
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent pb-5">
              <div className="text-muted text-center mt-2 mb-4">
                <small>Sign up with</small>
              </div>
              <div className="btn-wrapper d-flex justify-content-center">
                {/* <LoginWithFacebook onSignIn={dispatchSignInFacebook} /> */}
                <LoginWithGoogle dispatchSignInGoogle={handleGoogleRegister} />
              </div>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              {validatingSocialSignin ? (
                <div
                  style={{
                    height: "100px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spinner color="primary" />
                </div>
              ) : (
                <Form role="form" onSubmit={handleSubmit}>
                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-hat-3" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Username"
                        type="text"
                        name="username"
                        value={username.value}
                        onChange={updateNewUser}
                        onBlur={onFocusOut}
                        disabled={isSocialUser}
                        formNoValidate
                        invalid={!!fieldFeedback.username}
                      />
                      <FormFeedback className="ml-2">
                        {fieldFeedback.username}
                      </FormFeedback>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email"
                        type="email"
                        autoComplete="new-email"
                        name="email"
                        value={email.value}
                        onChange={updateNewUser}
                        disabled={isSocialUser}
                        onBlur={onFocusOut}
                        invalid={!!fieldFeedback.email}
                      />
                      <FormFeedback className="ml-2">
                        {fieldFeedback.email}
                      </FormFeedback>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    style={{ display: isSocialUser ? "none" : "block" }}
                  >
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type="password"
                        autoComplete="new-password"
                        name="password"
                        value={password.value}
                        onChange={updateNewUser}
                        onBlur={onFocusOut}
                        invalid={!!fieldFeedback.password}
                      />
                      <FormFeedback
                        className={`ml-2 ${fieldFeedback.password?.color}-feedback`}
                      >
                        {fieldFeedback.password?.message}
                      </FormFeedback>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    style={{ display: isSocialUser ? "none" : "block" }}
                  >
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Confirm password"
                        type="password"
                        autoComplete="new-password"
                        name="confirmPassword"
                        value={confirmPassword.value}
                        onChange={updateNewUser}
                        onBlur={onFocusOut}
                        invalid={!!fieldFeedback.confirmPassword}
                      />
                      <FormFeedback className="ml-2">
                        {fieldFeedback.confirmPassword}
                      </FormFeedback>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend" className="disabled">
                        <InputGroupText>
                          <i className="fas fa-link" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Referral Code"
                        type="text"
                        className="text-uppercase"
                        autoComplete="new-password"
                        name="refCode"
                        value={referralCode ?? refCode.value}
                        disabled={!!referralCode}
                        onChange={updateNewUser}
                        onBlur={onFocusOut}
                        maxLength={8}
                        invalid={!!fieldFeedback.refCode}
                      />
                      <FormFeedback className="ml-2">
                        {fieldFeedback.refCode}
                      </FormFeedback>
                    </InputGroup>
                  </FormGroup>
                  <Row className="my-4">
                    <Col xs="12">
                      <div className="custom-control custom-control-alternative custom-checkbox d-flex align-items-center">
                        <input
                          className="custom-control-input"
                          id="customCheckRegister"
                          type="checkbox"
                          name="agree"
                          checked={agree.value}
                          onChange={updateNewUser}
                          onBlur={onFocusOut}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customCheckRegister"
                        >
                          <span className="text-muted d-flex align-items-start">
                            I agree with the{" "}
                            <ul className="d-inline-block">
                              <li><a href="/privacy">
                                Privacy Policy
                              </a></li>
                              <li><a href="/privacy">
                                Terms & Condtions
                              </a></li>
                              <li><a href="/privacy">
                                Notice of Privacy Practices
                              </a></li>
                            </ul>
                          </span>
                        </label>
                      </div>
                    </Col>
                  </Row>

                  <Row className="my-4">
                    <Col xs="12">
                      <div className="custom-control custom-control-alternative custom-checkbox">
                        <input
                          className="custom-control-input"
                          id="shareDataConsent"
                          type="checkbox"
                          name="dataConsent"
                          checked={dataConsent.value}
                          onChange={updateNewUser}
                          onBlur={onFocusOut}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="shareDataConsent"
                        >
                          <span className="text-muted">
                            I understand and consent that data shared on Health
                            X Change may be anonymized and used for
                            pharmaceutical research or personalized marketing
                            for healthcare products or services
                          </span>
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <div className="m-auto">
                      <ReCAPTCHA
                        sitekey={appConfig.GOOGLE_RECAPTCHA_CLIENT_ID}
                        onExpired={() => setIsVerified(false)}
                        onError={() => setIsVerified(false)}
                        onChange={() => setIsVerified(true)}
                      />
                    </div>
                  </Row>
                  <div className="text-center">
                    <Button
                      className="mt-4"
                      color="primary"
                      type="submit"
                      disabled={
                        !isFormValid ||
                        !agree.value ||
                        loading ||
                        !isVerified ||
                        !dataConsent.value
                      }
                    >
                      {loading && (
                        <Spinner
                          as="span"
                          animation="border"
                          role="status"
                          size="sm"
                          className="mr-2"
                        />
                      )}
                      <span className="visually-hidden">Create account</span>
                    </Button>
                    {formFeedback?.type && (
                      <FormFeedback
                        className={`mt-3 d-block text-${formFeedback.type}`}
                      >
                        {formFeedback?.message}
                      </FormFeedback>
                    )}
                  </div>
                </Form>
              )}
            </CardBody>
          </Card>
        )}
      </Col>
    </>
  );
};

const mapStateToProps = (state) => ({ authUser: state.auth });

export default connect(mapStateToProps)(Register);
