export const uiActions = {
    SET_SYNC_MODAL_STATE: "SET_SYNC_MODAL_STATE",
};

const initState = {
    syncModal: false
};

const reducer = (state = initState, action) => {
    switch (action.type) {
        case uiActions.SET_SYNC_MODAL_STATE: {
            return { ...state, syncModal: action.payload };
        }
        default:
            return state;
    }
};

export default reducer;
