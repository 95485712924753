import { REGISTRATIONS_STATUS } from "../constants";
import { authActions } from "../reducers/authReducer";
import apiClient, { removeReqHeader, setReqHeader } from "../utils/apiClient";

export const registerUser = async (user) => {
  try {
    const registeredUser = await apiClient({
      method: "POST",
      url: "/auth/register",
      data: user,
    });
    return registeredUser;
  } catch (error) {
    throw new Error(error.response.data);
  }
};


// export const getContactInfo = async () => {
//   try {
//     const response = await apiClient({
//       method: "GET",
//       url: "/app/get-contact",
//     });
//     return response.data;
//   } catch (error) {
//     throw new Error(error.response.data);
//   }
// }

// export const saveContactInfo = async (contactInfo) => {
//   try {
//     await apiClient({
//       method: "POST",
//       url: "/app/update-contact",
//       data: contactInfo,
//     });
//     return true;
//   } catch (error) {
//     throw new Error(error.response.data);
//   }
// }

export const signInUser = (userData) => async (dispatch) => {
  dispatch({ type: authActions.AUTH_LOGIN_REQUEST });
  try {
    const signInDetails = await apiClient({
      method: "POST",
      url: "/auth/signin",
      data: userData,
    });
    const { user, refreshToken, authToken } = signInDetails.data;
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("refreshToken", refreshToken);
    setReqHeader('Authorization', authToken);
    dispatch({
      type: authActions.AUTH_LOGIN_REQ_SUCCESS,
      payload: {user, refreshToken, authToken},
    });
  } catch (error) {
    dispatch({
      type: authActions.AUTH_LOGIN_REQ_FAILED,
      payload: error.response ? error.response.data : error.message,
    });
  }
};

export const checkLoginUserCache = () => (dispatch) => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user) {
    dispatch({ type: authActions.AUTH_USER_DETAILS_SET, payload: user });
  }
};

export const signOutUser = () => async (dispatch) => {
  localStorage.removeItem("user");
  const refreshToken = localStorage.getItem("refreshToken");
  localStorage.removeItem("refreshToken");
  removeReqHeader('Authorization');
  removeReqHeader('AuthProvider');
  dispatch({ type: authActions.AUTH_USER_SIGNOUT });
  await apiClient({
    method: "post",
    url: "/auth/signout",
    data: { refreshToken }
  });
};

export const checkOpenRegistrations = async () => {
  try {
    const response = await apiClient({
      method: 'get',
      url: '/auth/check-registrations-open'
    });
    const { data: { status } = { status: REGISTRATIONS_STATUS.CLOSED } } = response;
    return status;
  } catch (error) {
    return REGISTRATIONS_STATUS.CLOSED;
  }
}

export const signInSocial = (url, credential) => async dispatch => {
  dispatch({ type: authActions.AUTH_LOGIN_REQUEST });
  try {
    const signInDetails = await apiClient({
      method: "POST",
      url,
      data: { credential },
    });
    const { user, refreshToken, authToken } = signInDetails.data;
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("refreshToken", refreshToken);
    setReqHeader('Authorization', authToken);
    dispatch({
      type: authActions.AUTH_LOGIN_REQ_SUCCESS,
      payload: {user, refreshToken, authToken},
    });
  } catch (error) {
    dispatch({
      type: authActions.AUTH_LOGIN_REQ_FAILED,
      payload: error.response ? error.response.data : error.message,
    });
  }
}

export const verifyEmail = async (email, token) => {
  try {
    const response = await apiClient({
      method: 'post',
      url: '/auth/confirmation',
      data: {
        email,
        token
      }
    });
    return response.data;
  } catch (error) {
    if (error.code === "ERR_BAD_REQUEST") {
      return error.response.data;
    }
    return error.message;
  }
};

export const getGoogleUser = async (credential) => {
  try {
    const response = await apiClient({
      method: "post",
      url: '/auth/validate/google',
      data: { credential }
    });
    return response.data;
  } catch (error) {
    console.log('Failed to validate google user: ', error.message);
    return false;
  }
}

export const getFacebookUser = async (credential) => {
  try {
    const response = await apiClient({
      method: "post",
      url: "/auth/validate/fb",
      data: { credential }
    });
    return response.data;
  } catch (error) {
    console.log('Failed to validate facebook user: ', error.message);
    return  false;
  }
}