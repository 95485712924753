/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "reactstrap";
import { appConfig } from "../../utils/config";

const { useCallback, useEffect } = require("react");

const LoginWithGoogle = ({ dispatchSignInGoogle }) => {
  const handleCallbackResponse = useCallback((response) => {
    dispatchSignInGoogle(response.credential);
  }, []);

  useEffect(() => {
    window.google?.accounts?.id?.initialize({
      client_id: appConfig.GOOGLE_CLIENT_ID,
      callback: handleCallbackResponse,
    });
    window.google?.accounts?.id.renderButton(
      document.getElementById("signInWithGoogle"),
      { size: "large", text: "Sign In With Google", width: '100%', logo_alignment: "center", dataWidth: 400 }
    );
  }, []);

  return (
    <Button className="p-0 shadow">
      <div id="signInWithGoogle"></div>
    </Button>
  );
};

export default LoginWithGoogle;
