import { useMemo } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Label,
  ListGroup,
  ListGroupItem,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import moment from "moment";
import useFlexpa from "../utils/useFlexpa";
import { LINK_STATUS } from "../constants";

const camelToProper = (text) => {
  const result = text.replace(/([A-Z])/g, " $1");
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
};

const ExplanationOfBenefit = ({ dataArr }) => {
  return (
    <Card className="mt-7 border shadow border">
      <CardHeader className="bg-green">Explanation Of Benefit</CardHeader>
      <CardBody>
        {dataArr?.length > 0 ? (
          <Table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Diagnosis</th>
                <th>Procedure</th>
              </tr>
            </thead>
            <tbody>
              {dataArr?.map?.((entry) => {
                const procedures = entry?.procedure?.map(
                  (item) =>
                    item.procedureCodeableConcept.text ||
                    item.procedureCodeableConcept.coding[0].display
                );
                const diagnosis = entry?.diagnosis?.map(
                  (item) =>
                    item?.diagnosisCodeableConcept?.text ||
                    item?.diagnosisCodeableConcept?.coding?.map(
                      (code) => code.display
                    )
                );
                return (
                  <tr key={entry?.id}>
                    <th scope="row">
                      {moment(entry?.created).format("DD MMM YYYY")}
                    </th>
                    <td>
                      <ul>
                        {diagnosis?.map?.((item) => (
                          <li key={item}>{item}</li>
                        ))}
                      </ul>
                    </td>
                    <td>
                      <ul>
                        {procedures?.map?.((item) => (
                          <li key={item}>{item}</li>
                        ))}
                      </ul>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        ) : (
          <Label className="m-3 text-muted">
            Seems like you have already linked your provider. <br />
            However, no data is available
          </Label>
        )}
      </CardBody>
    </Card>
  );
};

const FlexpaNotification = ({ handleFlexpaLinkClick, status }) => {
  return (
    <Row>
      <Col className="mt-9 d-flex align-items-center flex-column">
        {[LINK_STATUS.PENDING, LINK_STATUS.STARTED].includes(status) ? (
          <span className="m-3 mb-5 text-muted text-center">
            Thank you for connecting your health data! <br />
            <strong>
              Please allow 2-3 minutes to extract data from your health
              provider.
            </strong>
          </span>
        ) : [LINK_STATUS.UNKNOWN, LINK_STATUS.LINKED].includes(status) ? (
          <span className="m-3 mb-5 text-muted">
            Sorry, We couldn't find any data with your linked account.!
          </span>
        ) : (
          <>
            <span className="m-3 mb-5 text-muted">
              Looks like you haven't linked your health data yet. 🤑
            </span>
            <Button className="bg-success" onClick={handleFlexpaLinkClick}>
              Link your data from Health provider now
            </Button>
          </>
        )}
      </Col>
    </Row>
  );
};

export const FlexpaDataNotAvailableMsg = ({ flexpaLinks, className = "" }) => {
  return (
    <Row>
      <Col className={`${className} d-flex align-items-center flex-column`}>
        <span className="m-3 mb-5 text-muted">
          Looks like you do not have insurence data. we found only below
          information from your health system
          <ListGroup className="mt-4">
            {Object.keys(flexpaLinks || {})
              ?.filter((item) => !!flexpaLinks[item])
              ?.map?.((item) => (
                <ListGroupItem key={item}>{camelToProper(item)}</ListGroupItem>
              ))}
          </ListGroup>
        </span>
      </Col>
    </Row>
  );
};

const FlexpaLinkPage = () => {
  const { isLoading, flexpaData, flexpaLink, openFlexpa } = useFlexpa();
  const { status: linkStatus, links: flexpaLinks } = flexpaLink || {};

  const { explanationOfBenefit } = useMemo(() => {
    if (flexpaData) {
      return flexpaData;
    } else {
      return {};
    }
  }, [flexpaData]);

  const benefitsArr = useMemo(() => {
    if (!explanationOfBenefit) {
      return null;
    }
    let conditionsArr = explanationOfBenefit?.entry
      ?.filter?.(
        (item) => item?.resource?.diagnosis && item?.resource?.procedure
      )
      ?.map?.((entry) => entry?.resource)
      ?.sort((a, b) => (a.created > b.created ? -1 : 1));
    return conditionsArr;
  }, [explanationOfBenefit]);

  if (isLoading) {
    return (
      <Container>
        <Row className="justify-content-center">
          <Card className="mt-9 shadow">
            <CardBody className="d-flex align-items-center">
              <Spinner as="span" animation="border" role="status" size="sm" />
              <Label className="text-info mx-4 my-auto">{isLoading}</Label>
            </CardBody>
          </Card>
        </Row>
      </Container>
    );
  }

  return (
    <Container fluid>
      {explanationOfBenefit ? (
        <Row className="justify-content-center">
          <Col xs={12} lg={12}>
            <ExplanationOfBenefit dataArr={benefitsArr} />
          </Col>
        </Row>
      ) : Object.keys(flexpaData || [])?.length > 0 ? (
        <FlexpaDataNotAvailableMsg flexpaLinks={flexpaLinks} className="mt-9" />
      ) : (
        <FlexpaNotification
          handleFlexpaLinkClick={openFlexpa}
          status={linkStatus}
        />
      )}
    </Container>
  );
};

export default FlexpaLinkPage;
