import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
  Popover,
  PopoverBody,
  PopoverHeader,
  Row,
  Spinner,
} from "reactstrap";
import apiClient from "../../utils/apiClient";
import useFlexpa from "../../utils/useFlexpa";
import { LINK_STATUS, credits } from "../../constants";
import { getReferredList } from "../../actions/referrals";
import { useDispatch, useSelector } from "react-redux";
import { refActions } from "../../reducers/refReducer";
import moment from "moment";
import { useHistory } from "react-router-dom";

const InfoPopover = ({ target, isOpen, toggle, placement = "right" }) => {
  return (
    <Popover
      trigger="focus"
      target={target}
      isOpen={isOpen}
      toggle={toggle}
      placement={placement}
    >
    <PopoverHeader className="bg-info">Stay Tuned</PopoverHeader>
      <PopoverBody>
          These point represent your contributions to our platform and will be a factor in determining the # of tokens you recieve!
      </PopoverBody>
    </Popover>
  );
};

export const RoyalityStakeCard = () => {
  const [loading, setLoading] = useState(false);
  const [wallet, setWallet] = useState({});
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const { isLoading, flexpaData } = useFlexpa();

  useEffect(() => {
    let isMounted = true;
    const getWallet = async () => {
      try {
        setLoading(true);
        const response = await apiClient({
          method: "get",
          url: "/wallet",
        });
        if (isMounted) {
          setLoading(false);
          setWallet(response.data);
        }
      } catch (error) {
        setLoading(false);
        setWallet({});
        console.log("error: ", error);
      }
    };
    getWallet();
    return () => {
      isMounted = false;
    };
  }, [flexpaData]);

  return (
    <Card className="card-stats h-100 mb-4 mb-xl-0">
      <CardBody>
        <Row>
          <div className="col">
            <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
              Royalty Points
            </CardTitle>
            <span className="h2 font-weight-bold mb-0">
              {wallet.balance || "-"}
            </span>
          </div>
          <Col className="col-auto">
            <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
              {loading || isLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  role="status"
                  size="sm"
                  className="mr-2"
                />
              ) : (
                <i className="fas fa-wallet" />
              )}
            </div>
          </Col>
        </Row>
        <p className="mt-3 mb-0 text-muted text-sm">
          <span className="text-success mr-2">
            <i className="fa fa-arrow-up" /> {wallet?.transaction?.amount}
          </span>{" "}
          <span className="text-nowrap">{wallet?.transaction?.remarks}</span>
          <span
            tabIndex="0"
            className="float-right"
            style={{
              cursor: "pointer",
              position: "absolute",
              top: 0,
              right: 5,
            }}
            id="revenue-popover"
            onClick={(e) => {
              e.stopPropagation();
              setPopoverOpen(true);
            }}
          >
            <i className="fa fa-info-circle" />
          </span>
        </p>
        <InfoPopover
          target="revenue-popover"
          isOpen={isPopoverOpen}
          toggle={() => setPopoverOpen(!isPopoverOpen)}
        />
      </CardBody>
    </Card>
  );
};

export const OfferCard = () => {
  return (
    <Card className="card-stats h-100 mb-4 mb-xl-0">
      <CardBody>
        <Row>
          <div className="col">
            <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
              Offer
            </CardTitle>
            <span className="h2 font-weight-bold mb-0 text-muted">
              Coming Soon!
            </span>
          </div>
          <Col className="col-auto">
            <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
              <i className="fas fa-bullhorn" />
            </div>
          </Col>
        </Row>
      </CardBody>
      <Row className="bg-info-light w-100 mx-0 rounded-bottom">
        <Col className="col my-2">
          <p className="text-sm text-dark m-0">
            Stay tuned for more ways to earn even more royalties!!!
          </p>
        </Col>
      </Row>
    </Card>
  );
};

export const SyncDataModalCard = () => {
  const { openFlexpa, flexpaLink: { status, isLoading } = {} } = useFlexpa();
  const history = useHistory();
  const isDataLinked = useMemo(
    () =>
      [
        LINK_STATUS.STARTED,
        LINK_STATUS.PENDING,
        LINK_STATUS.LINKED,
        LINK_STATUS.UNKNOWN,
      ].includes(status),
    [status]
  );

  const openSycnModalState = async () => {
    if (
      [
        LINK_STATUS.STARTED,
        LINK_STATUS.PENDING,
        LINK_STATUS.LINKED,
        LINK_STATUS.UNKNOWN,
      ].includes(status)
    ) {
      history.push("/admin/referrals");
    } else {
      openFlexpa();
    }
  };

  return (
    <Card
      className="card-stats h-100 mb-4 mb-xl-0"
      onClick={openSycnModalState}
      style={{ cursor: "pointer" }}
    >
      <CardBody>
        <Row>
          <div className="col">
            <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
              Earn more
            </CardTitle>
            <span className="h2 font-weight-bold mb-0 text-muted">
              {isDataLinked
                ? "Refer your friends & family"
                : "Connect health data to earn 700 more points"}
            </span>
          </div>
          <Col className="col-auto">
            <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
              {isLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  role="status"
                  size="sm"
                  className="mr-2"
                />
              ) : (
                <i className="fa fa-coins" />
              )}
            </div>
          </Col>
        </Row>
      </CardBody>
      {isDataLinked && (
        <Row className="bg-info-light w-100 mx-0 rounded-bottom">
          <Col className="col my-2">
            <p className="text-sm text-dark m-0">
              Earn { credits.REFERRAL_BONUS } points per referral!!!
            </p>
          </Col>
        </Row>
      )}
    </Card>
  );
};

export const TotalReferrals = () => {
  const dispatch = useDispatch();
  const { referrals = [], error = "" } = useSelector((state) => ({
    ...state.refs,
  }));

  const fetchTotalReferrals = useCallback(async () => {
    dispatch({ type: refActions.GET_REFERRALS });
    const response = await getReferredList();
    if (!response.length) {
      dispatch({
        type: refActions.GET_REFERRALS_FAILED,
        payload: "No referrals.",
      });
    }
    dispatch({ type: refActions.GET_REFERRALS_SUCCESS, payload: response });
  }, [dispatch]);

  useEffect(() => {
    fetchTotalReferrals();
  }, [fetchTotalReferrals]);

  return (
    <div>
      <Card className="card-stats h-100 shadow">
        <CardBody>
          <Row>
            <Col>
              <CardTitle className="text-uppercase text-muted mb-0">
                Total Referrals
              </CardTitle>
              {error ? (
                <span className="text-dark mb-0">{error}</span>
              ) : (
                <span className="h2 font-weight-bold mb-0">
                  {referrals.length || "None"}
                </span>
              )}
            </Col>
            <Col className="col-auto">
              <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                <i className="fas fa-users" />
              </div>
            </Col>
          </Row>
        </CardBody>
        <Row className="bg-info-light w-100 mx-0 rounded-bottom">
          <Col className="col my-2">
            <p className="text-sm text-dark m-0">
              <i className="fa fa-info-circle"></i>&nbsp; 
              Once your friend signs up and connects their health data, you both{" "}
              <strong>earn 500</strong> royalty points
            </p>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export const ReferralsListTable = () => {
  const { referrals = [], isLoading = false } = useSelector((state) => ({
    ...state.refs,
  }));

  if (isLoading) {
    return (
      <Spinner
        as="span"
        animation="border"
        role="status"
        size="sm"
        className="mr-2"
      />
    );
  }

  if (!referrals || !referrals.length) {
    return null;
  }

  return (
    <Card className="card-stats h-100">
      <CardBody>
        <CardTitle className="text-uppercase text-muted mb-0">
          Your Referrals
        </CardTitle>
        <ListGroup className="mt-2">
          {referrals?.map?.((user, idx) => {
            return (
              <ListGroupItem key={user.referee?.email} className="py-1">
                <ListGroupItemHeading className="mb-0 d-flex justify-content-between">
                  <div>
                    <Badge
                      className="mr-2"
                      style={{ fontSize: 12, fontWeight: "bold" }}
                    >
                      {idx + 1}
                    </Badge>
                    {user.referee?.username}{" "}
                    <i
                      title={
                        user.isCredited
                          ? "+100 points credited"
                          : "You will gain 100 points once the user links their health data."
                      }
                      id={`referee-${user.referee?._id}`}
                      className={`fa ${
                        user.isCredited
                          ? "fa-check-circle text-success"
                          : "fa-exclamation-circle text-warning"
                      }`}
                    />
                  </div>
                  <ListGroupItemText className="mb-0" style={{ fontSize: 10 }}>
                    {moment(user.createdAt).fromNow()}
                  </ListGroupItemText>
                </ListGroupItemHeading>
              </ListGroupItem>
            );
          })}
        </ListGroup>
      </CardBody>
    </Card>
  );
};
