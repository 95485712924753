export const appConfig = {
  GOOGLE_CLIENT_ID: "552391508843-2019ruv6gllo727tp3khq8ft709dcr4k.apps.googleusercontent.com",
  FACEBOOK_APP_ID: "680528694091721",
  GOOGLE_RECAPTCHA_CLIENT_ID: "6Lf4-wcnAAAAADFqvjbQ9bwz1Ms3GkGVxbvhmT1n"
}

export const FLEXPA_LINKS_EXCEEDED_LIMIT = <div>
  <div>We have received an overwhelming number of responses and sign ups, Thank you so much! </div>
  <br />
  <div>Unfortunately, we can not onboard new users as part of this beta run 😦 We are working on opening up HXC for new users ASAP
    please check back soon!</div>
</div>