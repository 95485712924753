// reactstrap components
import { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  FormFeedback,
  Spinner,
} from "reactstrap";
import { signInUser, verifyEmail } from "../../actions/authActions";
import { useForm } from "../../utils/useForm";
import LoginWithGoogle from "../../components/GoogleLogin/LoginWithGoogle";
import { Link, useParams } from "react-router-dom";
import { loadLoginCredentials, saveLoginCredentials } from "./rememberme";

const Login = ({
  authUser,
  dispatchSignInUser,
  dispatchSignInGoogle,
  dispatchSignInFacebook,
  intent,
}) => {
  const [loginUser, updateLoginUser] = useForm(() => {
    const rememberedAuth = loadLoginCredentials();
    return ({ email: { value: rememberedAuth.username }, password: { value: rememberedAuth.password }, remember: { value: !!(rememberedAuth.username && rememberedAuth.password) } });
  });
  const params = useParams();
  const { loading, error } = authUser;
  const { email = {}, password = {}, remember = {} } = loginUser;

  useEffect(() => {
    const verify = async (email, token) => {
      await verifyEmail(email, token);
    }
    if (intent === "confirmation" && params.email && params.token) {
      verify(params.email, params.token)
    }
  }, [intent, params])

  const { fieldFeedback, isFormValid } = useMemo(() => {
    const { email = {}, password = {} } = loginUser;
    const messages = {};
    let isFormValid = true;
    if (!email.value) {
      messages.email = email.touched ? "Email is required" : "";
      isFormValid = false;
    }
    if (!password.value) {
      messages.password = password.touched ? "Password is required" : "";
      isFormValid = false;
    }
    return { fieldFeedback: messages, isFormValid };
  }, [loginUser]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const {
      email: { value: email },
      password: { value: password },
      remember: { value: remember }
    } = loginUser;
    saveLoginCredentials(email, password, remember);
    dispatchSignInUser({ email, password });
  };

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent pb-5">
            <div className="text-muted text-center mt-2 mb-3">
              <small>Sign in with</small>
            </div>
            <div className="btn-wrapper d-flex justify-content-center">
              {/* <LoginWithFacebook onSignIn={dispatchSignInFacebook} /> */}
              <LoginWithGoogle dispatchSignInGoogle={dispatchSignInGoogle} />
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Or sign in with credentials</small>
            </div>
            <Form role="form" onSubmit={handleSubmit}>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                    name="email"
                    value={email.value}
                    onChange={updateLoginUser}
                    invalid={!!fieldFeedback.email}
                  />
                  <FormFeedback className="ml-2">
                    {fieldFeedback.email}
                  </FormFeedback>
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    autoComplete="new-password"
                    name="password"
                    value={password.value}
                    onChange={updateLoginUser}
                    invalid={!!fieldFeedback.password}
                  />
                  <FormFeedback className="ml-2">
                    {fieldFeedback.password}
                  </FormFeedback>
                </InputGroup>
              </FormGroup>
              <div className="custom-control custom-control-alternative custom-checkbox">
                <input
                  className="custom-control-input"
                  id=" customCheckLogin"
                  type="checkbox"
                  name="remember"
                  checked={remember.value}
                  // value={remember.value}
                  onChange={updateLoginUser}
                />
                <label
                  className="custom-control-label"
                  htmlFor=" customCheckLogin"
                >
                  <span className="text-muted">Remember me</span>
                </label>
              </div>
              <div className="text-center">
                <Button
                  className="my-4"
                  color="primary"
                  type="submit"
                  disabled={!isFormValid || loading}
                >
                  {loading && (
                    <Spinner
                      as="span"
                      animation="border"
                      role="status"
                      size="sm"
                      className="mr-2"
                    />
                  )}
                  <span className="visually-hidden">Sign in</span>
                </Button>
                {error && (
                  <FormFeedback className={`mt-3 d-block`}>
                    {error}
                  </FormFeedback>
                )}
                <Col className="mt-3">
                  <Link to="/auth/register" className="text-light">
                    <small>Create new account</small>
                  </Link>
                </Col>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

const mapStateToProps = (state) => ({
  authUser: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchSignInUser: async (userInput) => dispatch(signInUser(userInput)),
  dispatchSignInGoogle: (credential) =>
    dispatch(signInUser({ credential, iss: 'google' })),
  dispatchSignInFacebook: (credential) =>
    dispatch(signInUser({ credential, iss: 'facebook' })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
