import { Link } from "react-router-dom";
// reactstrap components
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  DropdownMenu,
  DropdownItem,
  Media,
  UncontrolledDropdown,
  DropdownToggle,
} from "reactstrap";
import useAuth from "../../utils/useAuth";

const AuthNavbar = () => {
  const { auth = {}, dispatchSignOut } = useAuth();
  return (
    <>
      <Navbar className="navbar-top navbar-horizontal navbar-dark" expand="md">
        <Container className="px-4">
          <NavbarBrand to="/" tag={Link}>
            <img
              alt="..."
              src={require("../../assets/img/brand/healthxchange.webp")}
            />
          </NavbarBrand>
          <button className="navbar-toggler" id="navbar-collapse-main">
            <span className="navbar-toggler-icon" />
          </button>
          <UncontrolledCollapse navbar toggler="#navbar-collapse-main">
            <div className="navbar-collapse-header d-md-none">
              <Row>
                <Col className="collapse-brand" xs="6">
                  <Link to="/">
                    <img
                      alt="..."
                      src={require("../../assets/img/brand/healthxchange.webp")}
                    />
                  </Link>
                </Col>
                <Col className="collapse-close" xs="6">
                  <button className="navbar-toggler" id="navbar-collapse-main">
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            {auth.username ? (
              <Nav className="ml-auto" navbar>
                <UncontrolledDropdown nav>
                  <DropdownToggle className="pr-0" nav>
                    <Media className="align-items-center">
                      <Media className="ml-2 d-none d-lg-block">
                        <span className="nav-link-inner--text font-weight-bold" style={{ color: "rgb(24, 33, 109)" }}>
                          {auth.username}
                        </span>
                      </Media>
                    </Media>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow" right>
                    <DropdownItem className="noti-title" header tag="div">
                      <span className="text-overflow m-0" style={{ color: "rgb(24, 33, 109)" }}>Welcome!</span>
                    </DropdownItem>
                    <DropdownItem to="/admin/profile" tag={Link}>
                      <i className="ni ni-single-02" />
                      <span>My Profile</span>
                    </DropdownItem>
                    <DropdownItem to="/privacy" tag={Link}>
                      <i className="ni ni-settings-gear-65" />
                      <span>Security & Privacy</span>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        dispatchSignOut();
                      }}
                    >
                      <i className="ni ni-user-run" />
                      <span>Logout</span>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
            ) : (
              <Nav className="ml-auto" navbar>
                <NavItem>
                  <NavLink
                    className="nav-link-icon"
                    style={{ color: "rgb(24, 33, 109)" }}
                    to="/privacy"
                    tag={Link}
                  >
                    <span className="nav-link-inner--text font-weight-bold">
                      Security & Privacy{" "}
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className="nav-link-icon"
                    style={{ color: "rgb(24, 33, 109)" }}
                    to="/auth/login"
                    tag={Link}
                  >
                    <i className="ni ni-key-25" />
                    <span className="nav-link-inner--text font-weight-bold">
                      Login
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className="nav-link-icon"
                    style={{ color: "rgb(24, 33, 109)" }}
                    to="/auth/register"
                    tag={Link}
                  >
                    <i className="ni ni-circle-08" />
                    <span className="nav-link-inner--text font-weight-bold">
                      Register
                    </span>
                  </NavLink>
                </NavItem>
              </Nav>
            )}
          </UncontrolledCollapse>
        </Container>
      </Navbar>
    </>
  );
};

export default AuthNavbar;
