import Register from "./pages/auth/Register";
import Login from "./pages/auth/Login";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsConditions from "./pages/TermsConditions";
import Profile from "./views/Profile";
import Home from "./pages/Home";
import FlexpaLinkPage from "./views/Flexpa";
import YourData from "./views/YourData";
import ReferralsPage from "./views/Referrals";

var routes = [
  {
    path: "/index",
    name: "Home",
    icon: "fa fa-home text-green",
    component: YourData,
    layout: "/admin",
  },
  {
    path: "/profile",
    name: "My Profile",
    icon: "fas fa-user text-yellow",
    component: Profile,
    hideLink: true,
    layout: "/admin",
  },
  {
    path: "/flexpa",
    name: "Insurance Data",
    icon: "fas fa-database text-yellow",
    component: FlexpaLinkPage,
    layout: "/admin",
  },
  {
    path: "/referrals",
    name: "Refer & Earn",
    icon: "fas fa-user text-blue",
    component: ReferralsPage,
    layout: "/admin"
  },
  {
    path: "/wearables",
    name: "Wearables",
    icon: "ni ni-watch-time text-success",
    component: "",
    tooltip: "Coming soon",
    layout: "/admin"
  },
  {
    path: "/genomics",
    name: "Genomics",
    icon: "fas fa-dna text-info",
    component: "",
    tooltip: "Coming soon",
    layout: "/admin"
  },
  {
    path: "/clinical-data",
    name: "Clinical Data",
    icon: "fas fa-clinic-medical text-danger",
    component: "",
    tooltip: "Coming soon",
    layout: "/admin"
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/privacy",
    name: "PrivacyPolicy",
    icon: "ni ni-key-25 text-info",
    component: PrivacyPolicy,
    layout: "/public",
  },
  {
    path: "/terms",
    name: "TermsConditions",
    icon: "ni ni-key-25 text-info",
    component: TermsConditions,
    layout: "/public",
  },
  {
    path: "/register/:referralCode",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth",
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth",
  },
  {
    path: "/",
    name: "Welcome",
    icon: "ni ni-circle-08 text-pink",
    component: Home,
    layout: "",
  },
];
export default routes;
