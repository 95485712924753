import React, { useState } from "react";
import { Nav, NavLink, NavItem, TabContent, TabPane } from "reactstrap";
import TermsConditions from "./TermsConditions";
import NoticeOfPrivacyPractice from "./NoticeOfPrivacyPractice";

const PrivacyPolicy = () => {
  return (
    <div className="bg-white p-3 p-md-5 rounded-bottom">
      <div className="col-sm-10 py-5 mx-auto">
        <h1 className="text-center font-large">Privacy Policy</h1>
        <div className="privacy-policy-text text-left">
          <p>
            This Privacy Policy (“Privacy Policy”) explains Health X Change,
            Inc.’s (“we”, “us”, “our”, “Health X Change”) privacy practices for
            the activities described herein. Please read this Privacy Policy
            carefully to learn how we collect, use, share and process personal
            information, and User’s (“user,” “you,” ’your,” “patient,”) rights
            and choices regarding information we collect from or about you.
          </p>

          <p>
            General Terms: By using our website, our applications that run on
            mobile devices or tablets, or any online or mobile site or
            application that we own or control (collectively, our “Sites”),
            and/or by agreeing to this Privacy Policy (e.g. in the context of
            purchasing or utilizing any of our products or services, you
            understand and acknowledge that we will collect, process, use and
            share personal information as described in this Privacy Policy and
            consent to the practices described in this Privacy Policy.)
          </p>

          <h4>1. PERSONAL INFORMATION WE COLLECT</h4>
          <p>
            "In conducting every aspect of our business, we may collect personal
            information to help obtain your health data. The information we
            collect will vary depending on your interaction with us. Such
            information may include, without limitation: your name, addresses,
            email addresses, telephone numbers, date of birth, age, insurance
            information, gender, sex, protected health information, and other
            types of personal information that you choose to provide to us or
            that we may obtain about you. We collect personal information, as
            well as other information, in multiple ways. You are provided
            options to fill out information if you are comfortable sharing with
            our team when using our platform.
          </p>

          <p>
            We will not share identifying information to any outside third
            parties that you are not aware of. You may delete your profile at
            any time and we will not keep copies of your data on our servers.
          </p>

          <p>
            Information You Provide to Us: We collect information you provide to
            us. This may include, for example, when you request information or
            materials from us, visit or use our Sites, purchase our products or
            services, create an account in our application, register for an
            account on the customer portal, communicate with our customer
            service or sales teams, respond to a survey, or respond to our
            advertisements.
          </p>

          <p>
            Information We Collect from Other Sources: We may collect
            information about you from a variety of third parties. For example,
            we may obtain information about you from: covered entities such as
            health plans, health insurance companies, health care providers and
            healthcare clearinghouses; organizations, universities and private
            clinics conducting research studies or clinical trials; companies
            that search for, provide, and/or aggregate information from public
            records, such as LexisNexis Risk Solutions and Accurint; state and
            federal government agencies, such as the IRS and Medicare/Medicaid;
            credit bureaus and credit reporting agencies, such as Equifax; your
            existing health, medical, provider, or insurance accounts when you
            grant permission to access your accounts or information; social
            media networks; and publicly-available sources and data suppliers
            from which we obtain data to validate or supplement the information
            we hold.
          </p>

          <p>
            Information We Collect Automatically: When you use or visit our
            Sites, we collect some information automatically. For example, when
            you visit our website, we may collect device, usage and log
            information such as your computer’s operating system, Internet
            Protocol (IP) address, access times, browser type and language, the
            search engine you used to locate the website, and the website you
            visited before or after our site. In addition, we gather certain
            navigational information about where you go on our website to help
            us determine which areas of the website are most frequently visited
            and helps us to tailor the sites to the needs and interests of our
            online visitors. If you use our mobile applications or use our
            Services on a mobile device or tablet, we may also collect your
            device type, mobile phone number, operating system type, wireless
            carrier, and device IDs, on our mobile applications.
          </p>

          <p>
            Like most companies, we use technologies such as web beacons,
            pixels, tags, and JavaScript, alone or in conjunction with cookies,
            to gather this information. When you visit our website, we, or an
            authorized third party, place or recognize a unique cookie on your
            browser (including through use of pixel tags) that collects
            information, including personal information, about your online
            activities over time and across different sites. We also use web
            beacons and pixels in our emails to collect information about how
            you interact with our emails. For example, we may place a pixel in
            marketing emails that notify us when you click on a link in the
            email. If you want to remove or cookies and other collection
            technology, you may be able to update your browser settings (consult
            your browser’s “help” menu to learn how to remove or block cookies
            and similar technology). You can find instructions on how to manage
            collection technology on different types of web browsers at{" "}
            <a
              data-auto-recognition="true"
              href="http://www.allaboutcookies.org"
              target="_blank"
              rel="noreferrer"
            >
              www.allaboutcookies.org
            </a>
            .
          </p>

          <h4>2. HOW WE USE PERSONAL INFORMATION</h4>

          <p>We use your personal information to help us assist you.</p>
          <p>
            We may use your personal information to: Complete contracts as well
            as any disclosures or other documents required by law; Provide,
            develop, maintain, and improve our products and Services (e.g.
            evaluate the performance of our staff, assess the quality of our
            products and Services, and help us improve our website and
            processes);Process any applications, forms, requests, inquiries, or
            other information submitted to us; Send marketing communications,
            promotional offers, and periodic customer satisfaction, market
            research or quality assurance surveys; Communicate with you;
            Administer and process payments to you or from you; Create and
            update your customer account, including aggregating your health and
            medical records and treatment information; Allow creation,
            maintenance, customization, enrollment, registration, and securing
            of accounts on your behalf; Administer and support participation in
            sweepstakes, special offers, special pricing, discounts, and
            promotions; Personalize our products, websites, and Services,
            including content, ads and offerings; Perform research and
            analytical activities (e.g. identifying trends and the effectiveness
            of marketing campaigns); Solicit your participation in a clinical
            trial or research study; Conduct audits, security and fraud
            monitoring and prevention; Protect our legitimate business interests
            and legal rights; and Assist us with legal claims, compliance,
            regulatory and investigative purposes as necessary (including in
            connection with law enforcement investigations, legal process, or
            litigation).
          </p>

          <p>
            We may also use personal information we have collected and
            aggregated or anonymized personal information for any purpose
            permitted by law. For example, we may use this information to
            understand more about our users, such as by analyzing aggregated
            information to calculate the percentage of our users who have a
            particular telephone area code. This includes demographic data,
            inferred commercial interests, and other information we may collect
            from you or from third parties.
          </p>

          <h4>3. HOW WE SHARE PERSONAL INFORMATION</h4>

          <p>
            We have strict data sharing policies. We share data when these two
            things happen:
          </p>

          <p>
            1) upon express written consent from patient to monetize their
            personal health information AND
          </p>
          <p>
            2) the receiver who has been previously verified as a commercial
            pharmaceutical company, healthcare provider or governmental body
            that complies with all requisite U.S. laws and regulations (federal,
            state, local etc.)
          </p>

          <p>
            Sharing with Third Parties. We may share your personal information
            with third parties when you request that we do so or when it is
            necessary that we do so as described herein.
          </p>

          <p>
            Service Providers. We may share your personal information with third
            parties who work on behalf of, or with, us such as vendors,
            processors, suppliers, agents, attorneys, management companies,
            consultants, staffing companies, and representatives (collectively,
            "Service Providers”). Service Providers assist us with a variety of
            functions including, but not limited to, sending communications,
            assisting with analytics, conducting research or surveys, sending
            regular mail and e-mail, maintaining databases, providing software
            applications, or processing credit card or debit card payments.
          </p>

          <p>
            Clinical Trial Sponsors and Investigators. If you participate in a
            clinical trial or research study, we may share your personal
            information with the sponsor of the clinical trial or research study
            and the investigators involved in that trial or study or in related
            trials or studies.
          </p>

          <p>
            Government Agencies. We may share your personal information with
            government agencies, law enforcement, or authorized third parties in
            response to a request relating to a civil or criminal investigation
            or other alleged illegal activity. We may also share your personal
            information with government agencies such as the Department of
            Defense and the Secretary of the U.S. Department of Health and Human
            Services.
          </p>

          <p>
            Disclosures Under Special Circumstances. We may disclose your
            personal information to third parties under special circumstances:
            (i) where we have a good faith belief that such disclosure is
            necessary to meet any applicable law, regulation, legal process or
            other legal obligation; (ii) when we believe disclosure is necessary
            to protect or prevent harm, illegal activity, or financial loss;
            (iii) to detect, investigate and help prevent security, fraud or
            technical issues; (iv) to research entities for use in
            health-related analytics and insights; (v) to assist in managing or
            responding to public emergencies such as a disease outbreak or
            pandemic (vi) to enforce our Terms of Use, and (vii) to cooperate
            with law enforcement, government, quasi-governmental and public
            agency requests or reporting requirement.
          </p>

          <p>
            Corporate Transactions. We may transfer your personal information in
            the event we: (i) sell or transfer, or are considering selling or
            transferring, all or a portion of our business or assets; or (ii)
            are considering or engaging in any reorganization, conversion,
            merger, sale, joint venture, assignment, transfer or disposition of
            all or any portion of our ownership interest, business or
            operations.
          </p>

          <h4>4. USE AND DISCLOSURE OF PROTECTED HEALTH INFORMATION</h4>

          <p>
            We share a commitment with third parties to protect the privacy and
            confidentiality of Protected Health Information (“PHI”) that we
            obtain subject to the terms of a Business Associate Agreement. A
            Business Associate Agreement is a formal written contract between us
            and a third party that requires us to comply with specific
            requirements related to PHI. We may use PHI for our management,
            administration, data aggregation and legal obligations to the extent
            such use of PHI is permitted or required by the Business Associate
            Agreement and not prohibited by law. We may use or disclose PHI on
            behalf of, or to provide services to, third parties for purposes of
            fulfilling our service obligations to third parties, if such use or
            disclosure of PHI is permitted or required by the Business Associate
            Agreement and would not violate the Privacy Rule. In the event that
            PHI must be disclosed to a subcontractor or agent, we will ensure
            that the subcontractor or agent agrees to abide by the same
            restrictions and conditions that apply to us under the Business
            Associate Agreement with respect to PHI, including the
            implementation of reasonable and appropriate safeguards. We may also
            use PHI to report violations of law to appropriate federal and state
            authorities.
          </p>

          <h4>5. PERSONAL INFORMATION OF CHILDREN</h4>

          <p>
            We do not knowingly collect personal information of individuals
            under the age of 18 (a “child” or “children”) through our website or
            through the use of cookies. Parents and legal guardians have the
            right to review their child’s personal information, direct us to
            delete it, and refuse to allow any further collection or use of the
            child’s information. To exercise those rights, please contact us at{" "}
            <span style={{ textDecoration: "underline" }}>
              <a href="mailto:admin@health-x-change.com" target="_self">
                admin@health-x-change.com
              </a>
            </span>
            . If you are a parent or guardian and believe we may have
            inadvertently collected personal information from your child without
            your permission, please notify us immediately by sending an email to{" "}
            <span style={{ textDecoration: "underline" }}>
              <a href="mailto:admin@health-x-change.com" target="_self">
                admin@health-x-change.com
              </a>
            </span>
            .
          </p>

          <h4>6. “DO NOT TRACK” DISCLOSURE</h4>

          <p>
            We do not collect or respond to Do Not Track signals and our
            websites do not function differently based on any Do Not Track
            preferences that may be received. For more information on Do Not
            Track signals, please visit{" "}
            <a
              data-auto-recognition="true"
              href="https://allaboutdnt.com/."
              target="_blank"
              rel="noreferrer"
            >
              https://allaboutdnt.com/.
            </a>
          </p>

          <h4>7. ANALYTICS SERVICES</h4>

          <p>
            We may use analytics services provided by third party partners,
            which use cookies and other collection technology to collect and
            store information about the use of the Sites and the use of other
            websites, apps and online resources. For information on opting-out,
            please visit{" "}
            <a
              data-auto-recognition="true"
              href="http://optout.aboutads.info"
              target="_blank"
              rel="noreferrer"
            >
              http://optout.aboutads.info
            </a>
            , and{" "}
            <a
              data-auto-recognition="true"
              href="http://optout.networkadvertising.org"
              target="_blank"
              rel="noreferrer"
            >
              http://optout.networkadvertising.org
            </a>
            . For information on opting-out in mobile applications, please visit{" "}
            <a
              data-auto-recognition="true"
              href="https://www.networkadvertising.org/mobile-choice."
              target="_blank"
              rel="noreferrer"
            >
              https://www.networkadvertising.org/mobile-choice.
            </a>
          </p>

          <h4>8. LINKING TO OTHER SITES</h4>

          <p>
            Our website may contain links to other sites that we do not own or
            operate. We do not control, recommend or endorse and are not
            responsible for these sites or their content, products, services or
            privacy policies or practices. These other sites may send their own
            cookies to your device, they may independently collect information
            about you or from you, and they may or may not have their own
            published privacy policies.
          </p>

          <h4>9. PROTECTION OF PERSONAL INFORMATION</h4>

          <p>
            We store your information using reasonable physical, technical and
            administrative safeguards. Please be aware that the Sites and data
            storage are run on software, hardware and networks, any component of
            which may, from time to time, require maintenance or experience
            problems or breaches of security beyond our control. In addition, no
            transmission of data over the internet is guaranteed to be
            completely secure. It may be possible for third parties not under
            our control to intercept or access transmissions or private
            communications unlawfully. We cannot ensure or warrant the security
            of any information you transmit to us over the internet.
          </p>

          <h4>10.DATA RETENTION</h4>

          <p>
            We retain your personal information for as long as necessary to
            provide our services and fulfill the transactions you have
            requested, or for other essential purposes such as complying with
            our legal obligations, maintaining business and financial records,
            resolving disputes, maintaining security, detecting and preventing
            fraud and abuse, and enforcing our agreements. If you access the
            Sites or utilize our services on behalf of an organization, we
            retain your organizational contact details after the termination of
            your organization’s transaction to continue to communicate with you.
            We will retain your data in accordance with all applicable laws and
            regulations unless you provide express written consent to revoke our
            access.
          </p>

          <h4>11.MARKETING COMMUNICATIONS</h4>

          <p>
            If you no longer want to receive marketing-related emails from us on
            a going-forward basis, you may opt out by contacting us by email at{" "}
            <span style={{ textDecoration: "underline" }}>
              <a href="mailto:admin@health-x-change.com" target="_self">
                admin@health-x-change.com
              </a>
            </span>{" "}
            or by following the instructions in any such email you receive from
            us. We will try to comply with your request as soon as reasonably
            practicable. If you opt out of receiving marketing emails from us,
            we may still send you important administrative messages, from which
            you cannot opt out.
          </p>

          <h4>12.LOCATION OF PROCESSING</h4>

          <p>
            Subject to applicable law, we will transfer personal information
            collected in connection with the use of our Sites or services to the
            United States for processing. By providing personal information to
            us or using the Sites, you acknowledge and consent to the transfer
            and processing of such information in the United States.
          </p>

          <h4>13.CHANGES TO THIS PRIVACY POLICY</h4>

          <p>
            The Privacy Policy may be revised from time to time. The “Last
            Updated” legend at the bottom of this page indicates when this
            Privacy Policy was last revised. Any changes will become effective
            when we post the revised Privacy Policy on any of our websites. Your
            use of the Services following these changes means that you accept
            the revised Privacy Policy.
          </p>

          <h4>14. JURISDICTION-SPECIFIC PROVISIONS</h4>

          <p>a. California</p>

          <p>
            The California Consumer Privacy Act. Terms used in this section and
            not otherwise defined have the meaning given to them under the
            California Consumer Privacy Act of 2018 (“CCPA”). We do not sell
            personal information collected about you.
          </p>
          <p>
            In the preceding 12 months, we collected and disclosed for a
            business purpose the following categories of personal information
            about California consumers to
          </p>
          <p>
            service providers who process data on our behalf, research partners,
            and other third parties:
          </p>

          <p>Identifiers: Name, e-mail address, IP address, telephone number</p>
          <p>
            Personal information categories listed in the California Customer
            Records statute: Name, social security number, physical
            characteristics or description, telephone number, driver’s license
            or state identification card number, etc.
          </p>
          <p>
            Protected classification characteristics under California or federal
            law: Race, gender
          </p>
          <p>
            Commercial information: Records of products or Services purchased,
            obtained, or considered, including prescriptions
          </p>
          <p>
            Internet or other similar network activity: Information on a user’s
            interaction with the website
          </p>
          <p>Geolocation data: IP address data</p>
          <p>
            Professional or employment-related information: Title of profession,
            employer, etc.
          </p>
          <p>
            Inferences drawn from other personal information: Profile reflecting
            a person’s preferences
          </p>

          <p>
            In addition, to the extent they are contained within your Health
            Records:
          </p>

          <p>
            Biometric information: Imagery of retinas, fingerprints, hands,
            face, and behavioral characteristics
          </p>
          <p>
            Sensory data: Audio, electronic, visual, thermal, olfactory
            information
          </p>
          <p>
            Professional or employment-related information: Title of profession,
            employer, etc.
          </p>
          <p>&nbsp;</p>
          <p>
            Sources of Information. In the preceding 12 months, we received
            personal information from the sources described above Section I of
            this Privacy Policy.
          </p>
          <p>
            Purposes for Collection, Use, and Sharing. We use and disclose the
            personal information we collect for our commercial purposes, as
            further described in this Privacy Policy, including for our business
            purposes:
          </p>

          <p>
            ·&nbsp; &nbsp; &nbsp; &nbsp; Auditing related to our interactions
            with you;
          </p>
          <p>·&nbsp; &nbsp; &nbsp; &nbsp; Legal compliance;</p>
          <p>
            ·&nbsp; &nbsp; &nbsp; &nbsp; Detecting security incidents,
            protecting against malicious, deceptive, fraudulent, or illegal
            activity, and necessary prosecution;
          </p>
          <p>·&nbsp; &nbsp; &nbsp; &nbsp; Debugging;</p>
          <p>
            ·&nbsp; &nbsp; &nbsp; &nbsp; Performing Services (for us or our
            service provider);
          </p>
          <p>
            ·&nbsp; &nbsp; &nbsp; &nbsp; Internal research for technological
            improvement;
          </p>
          <p>·&nbsp; &nbsp; &nbsp; &nbsp; Internal operations;</p>
          <p>
            ·&nbsp; &nbsp; &nbsp; &nbsp; Activities to maintain and improve our
            Services; and
          </p>
          <p>·&nbsp; &nbsp; &nbsp; &nbsp; Other one-time or short-term uses.</p>

          <p>
            Your Rights. Where applicable, if you are a California resident you
            may have the following rights under CCPA in relation to “personal
            information” we have collected about you as defined in the CCPA;
            these rights are, to the extent required by the CCPA and subject to
            verification and any applicable exceptions:
          </p>

          <p>
            ·&nbsp; &nbsp; &nbsp; &nbsp; Right to Know/Access: You have the
            right to request that we disclose certain information to you about
            our collection and use of certain personal information about you as
            described below:
          </p>
          <p>
            ·&nbsp; &nbsp; &nbsp; &nbsp; The specific pieces of personal
            information collected;
          </p>
          <p>
            ·&nbsp; &nbsp; &nbsp; &nbsp; The categories of personal information
            collected;
          </p>
          <p>
            ·&nbsp; &nbsp; &nbsp; &nbsp; The categories of sources from whom the
            personal information is collected;
          </p>
          <p>
            ·&nbsp; &nbsp; &nbsp; &nbsp; The purpose for collecting the personal
            information; and
          </p>
          <p>
            ·&nbsp; &nbsp; &nbsp; &nbsp; The categories of third parties with
            whom we have shared the personal information.
          </p>
          <p>
            ·&nbsp; &nbsp; &nbsp; &nbsp; Right to Delete: You have the right to
            request that we delete the personal information.
          </p>
          <p>
            ·&nbsp; &nbsp; &nbsp; &nbsp; Freedom from Discrimination: You have
            the right to be free from unlawful discrimination for exercising any
            of the rights above.
          </p>

          <p>
            To make a request in relation to the above rights, please contact us
            using the information below.&nbsp; To fulfill your request, we will
            need to verify your identity and ask additional information and
            documents, which may include information previously provided.
          </p>

          <p>
            Only you, or someone legally authorized to act on your behalf, may
            make a request related to personal information collected about you.
            To designate an authorized agent, the authorized agent must provide
            sufficient information that allows us to reasonably verify that they
            have been authorized by you to act on their behalf.
          </p>
          <p>
            If you would like to exercise your rights discussed in this section,
            please contact us at the information below.
          </p>
          <p>&nbsp;</p>
          <p>15.CONTACT US</p>

          <p>
            In certain circumstances, you may be able to review and request
            changes to your personal information.
          </p>

          <p>
            If you would like to make changes or have any questions or concerns
            about this Privacy Policy or our privacy practices, please contact
            us at{" "}
            <span style={{ textDecoration: "underline" }}>
              <a href="mailto:admin@health-x-change.com" target="_self">
                admin@health-x-change.com
              </a>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

const PageWithTabs = () => {
  const [activeTabId, setActiveTabId] = useState(1);

  return (
    <div>
      <Nav className="justify-content-start">
        <NavItem className="col-6 col-md-3 p-0">
          <NavLink
            className={`p-2 rounded-top ${activeTabId === 1 ? "bg-secondary" : "bg-info text-white"}`}
            style={{ cursor: "pointer" }}
            active={activeTabId === 1}
            onClick={() => setActiveTabId(1)}
          >
            Privacy
          </NavLink>
        </NavItem>
        <NavItem className="col-6 col-md-3 p-0">
          <NavLink
            className={`p-2 rounded-top ${activeTabId === 2 ? "bg-secondary" : "bg-info text-white"}`}
            active={activeTabId === 2}
            style={{ cursor: "pointer" }}
            onClick={() => setActiveTabId(2)}
          >
            Terms & Conditions
          </NavLink>
        </NavItem>
        <NavItem className="col-6 col-md-3 p-0">
          <NavLink
            className={`p-2 rounded-top ${activeTabId === 3 ? "bg-secondary" : "bg-info text-white"}`}
            active={activeTabId === 3}
            style={{ cursor: "pointer" }}
            onClick={() => setActiveTabId(3)}
          >
            Notice of Privacy Practices
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTabId}>
        <TabPane tabId={1}>
          <PrivacyPolicy />
        </TabPane>
        <TabPane tabId={2}>
          <TermsConditions />
        </TabPane>
        <TabPane tabId={3}>
          <NoticeOfPrivacyPractice />
        </TabPane>
      </TabContent>
    </div>
  );
};

export default PageWithTabs;
