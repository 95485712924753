import apiClient from "../utils/apiClient";

export const getReferralConfig = async () => {
  try {
    const response = await apiClient({
      method: "GET",
      url: "/refs/code",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data);
  }
}

export const getReferredList = async () => {
  try {
    const response = await apiClient({
      method: 'GET',
      url: '/refs/list'
    });
    return response.data ?? [];
  } catch (error) {
    console.log('RAM: Failed to get referred list.');
    return []
  }
}