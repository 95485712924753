import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signOutUser } from "../actions/authActions";

const useAuth = () => {

  const { auth } = useSelector(state => ({ auth: state.auth }));
  const dispatch = useDispatch();

  const dispatchSignOut = useCallback(() => dispatch(signOutUser()), [dispatch]);

  return { auth, dispatchSignOut };
};

export default useAuth;