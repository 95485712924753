const LINK_STATUS = {
  STARTED: 'started',
  PENDING: 'pending',
  LINKED: 'linked',
  FAILED: 'failed',
  DISABLED: 'disabled',
  NOT_LINKED: 'not_linked',
  TRY_AGAIN: 'try_again',
  UNKNOWN: 'unknown'
}

const REGISTRATIONS_STATUS = {
  OPEN: 'OPEN',
  CLOSED: 'CLOSED'
}

const credits = {
  SIGNUP_BONUS: 300,
  HEALTH_SYSTEM_LINKED: 700,
  REFERRAL_BONUS: 500
};

module.exports = { LINK_STATUS, REGISTRATIONS_STATUS, credits };
