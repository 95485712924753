import { useMemo } from "react";
import { Timeline as TwitterTimeline } from "react-twitter-widgets";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  // Spinner,
} from "reactstrap";
import Header from "../components/Headers/Header.js";
// import Timeline from "../components/Timeline/Timeline.jsx";
import useFlexpa from "../utils/useFlexpa.js";
// import { FlexpaDataNotAvailableMsg } from "./Flexpa.js";
// import ReadMore from "../utils/ReadMore.jsx";

const YourData = () => {
  const { isLoading, flexpaData } = useFlexpa();

  const { explanationOfBenefit } = useMemo(() => {
    if (flexpaData) {
      return flexpaData;
    } else {
      return {};
    }
  }, [flexpaData]);

  const insuranceDetails = useMemo(() => {
    return explanationOfBenefit?.entry
      ?.filter((item) => item?.resource?.diagnosis)
      .map((entry) => {
        const procedures = entry?.resource?.procedure?.map(
          (item) =>
            item.procedureCodeableConcept.text ||
            item.procedureCodeableConcept.coding[0].display
        );
        return {
          ts: entry.resource.created,
          eventData: {
            title: procedures?.[0] || "Procedure",
            healthData: [
              {
                title: "Procedure",
                history: procedures,
              },
              {
                title: "Diagnosis",
                history: entry?.resource?.diagnosis?.map(
                  (item) =>
                    item?.diagnosisCodeableConcept?.text ||
                    item?.diagnosisCodeableConcept?.coding?.map(
                      (code) => code.display
                    )
                ),
              },
            ],
          },
        };
      });
  }, [explanationOfBenefit]);

  return (
    <>
      <Header isLoading={isLoading} />
      <Container className="mt--7" fluid>
        <Row>
          <Col xl="12" className="mt-3">
            <Card className="shadow bg-secondary">
              <CardBody>
                <Row>
                  <Col xl="8">
                    <Row className="d-flex flex-wrap">
                      <Col className="my-2" xs="12" xl="6">
                        <a
                          className="text-white"
                          target="https://medium.com/health-x-change-newsletter/your-health-data-can-save-lives-56c1aefa1114"
                          href="https://medium.com/health-x-change-newsletter/your-health-data-can-save-lives-56c1aefa1114"
                        >
                          <Card
                            className="article-card text-dark bg-transparent"
                            style={{ height: 100 }}
                          >
                            <CardHeader className="bold pt-2 px-2 pb-1 text-dark bg-transparent text-ellipsis">
                              <strong>Your health data can save lives!</strong>
                            </CardHeader>
                            <span className="card-separator" />
                            <CardBody className="text-sm pb-2 px-2 pt-1">
                              <p className="text-sm text-mute font-weight-600 position-relative m-0 p-0 read-more text-ellipsis-2">
                                In the era of rapidly advancing technology, the
                                healthcare landscape is undergoing a
                                transformative revolution. One of the key
                                drivers of this change is the average person’s
                                access to vast amounts of healthcare data about
                                themselves. From electronic health records to
                                wearable devices, individuals now have the
                                opportunity to contribute to the greater good by
                                sharing their healthcare data
                              </p>
                            </CardBody>
                          </Card>
                        </a>
                      </Col>
                      <Col className="my-2" xs="12" xl="6">
                        <a
                          className="text-dark"
                          target="https://medium.com/health-x-change-newsletter/health-x-change-turn-your-health-data-into-wealth-b50ed8907428"
                          href="https://medium.com/health-x-change-newsletter/health-x-change-turn-your-health-data-into-wealth-b50ed8907428"
                        >
                          <Card
                            className="article-card text-dark bg-transparent border-1"
                            style={{ height: 100 }}
                          >
                            <CardHeader className="bold pt-2 px-2 pb-1 text-dark bg-transparent text-ellipsis">
                              <strong>
                                Introducing Health X Change: Turn your health
                                data into wealth!
                              </strong>
                            </CardHeader>
                            <span className="card-separator" />
                            <CardBody className="text-sm pb-2 px-2 pt-1">
                              <p className="text-sm text-mute font-weight-600 position-relative m-0 p-0 read-more text-ellipsis-2">
                                In today's digital age, health data has become a
                                valuable resource. It's used by multiple
                                parties, from insurance companies to
                                pharmaceutical giants. It's often treated as a
                                commodity that benefits everyone except the
                                individuals generating it — until now.
                              </p>
                            </CardBody>
                          </Card>
                        </a>
                      </Col>
                      <Col className="my-2" xs="12">
                        <a
                          className="text-dark"
                          target="https://medium.com/health-x-change-newsletter/data-as-currency-a-vision-for-a-universal-basic-income-in-the-digital-age-265aac6b54e2"
                          href="https://medium.com/health-x-change-newsletter/data-as-currency-a-vision-for-a-universal-basic-income-in-the-digital-age-265aac6b54e2"
                        >
                          <Card
                            className="text-dark article-card shadow bg-transparent"
                            style={{ height: 100 }}
                          >
                            <CardHeader className="bold pt-2 px-2 pb-1 text-dark bg-transparent text-ellipsis">
                              <strong>
                                Data as Currency: A Vision for a Universal Basic
                                Income in the Digital Age
                              </strong>
                            </CardHeader>
                            <span className="card-separator" />
                            <CardBody className="text-sm pb-2 px-2 pt-1">
                              <p className="text-sm text-mute font-weight-600 position-relative m-0 p-0 read-more text-ellipsis-2">
                                In an age where data is as valuable as gold,
                                imagine a world where your everyday digital
                                footprints become a source of continuous income,
                                a sort of Universal Basic Income (UBI), but not
                                as we traditionally know it. This vision for the
                                future, where personal data transforms into an
                                asset, isn’t just a fantasy but a burgeoning
                                reality.
                              </p>
                            </CardBody>
                          </Card>
                        </a>
                      </Col>
                      <Col className="my-2" xs="12">
                        <a
                          className="text-dark"
                          target="https://medium.com/health-x-change-newsletter/what-is-decentralized-science-desci-851841c5ab89"
                          href="https://medium.com/health-x-change-newsletter/what-is-decentralized-science-desci-851841c5ab89"
                        >
                          <Card
                            className="text-dark article-card shadow bg-transparent"
                            style={{ height: 100 }}
                          >
                            <CardHeader className="bold pt-2 px-2 pb-1 text-dark bg-transparent text-ellipsis">
                              <strong>
                                What is Decentralized Science (DeSci)?
                              </strong>
                            </CardHeader>
                            <span className="card-separator" />
                            <CardBody className="text-sm pb-2 px-2 pt-1">
                              <p className="text-sm text-mute font-weight-600 position-relative m-0 p-0 read-more text-ellipsis-2">
                                The intersection of crypto, blockchain, and
                                science is giving rise to a revolutionary
                                concept known as Decentralized Science, or
                                DeSci. You have probably heard Brain Armstrong
                                (Coinbase CEO) speak about $RSC coin and CZ
                                (Binance ex-CEO) refer to biotech and DeSci as a
                                larger crypto narrative for 2024 — but what is
                                DeSci?
                              </p>
                            </CardBody>
                          </Card>
                        </a>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs="12" xl="4">
                    <Row className="h-100 py-2">
                      <Col>
                        <Card
                          className="h-100 text-dark article-card bg-transparent"
                          style={{ minHeight: 200 }}
                        >
                          <CardBody className="p-0 radius-14">
                            <TwitterTimeline
                              dataSource={{
                                sourceType: "profile",
                                screenName: "Health_XChange",
                              }}
                              options={{
                                height: "330",
                              }}
                            />
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            {/* <Card className="shadow bg-secondary">
              <CardHeader className="bg-white">
                <Row className="align-items-center justify-content-between">
                  <Col md="6" xs="12">
                    <h2 className="mb-0">Insurance claims data</h2>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {explanationOfBenefit ? (
                  <div className="p-3">
                    {isLoading ? (
                      <Spinner
                        as="span"
                        animation="border"
                        role="status"
                        ze="sm"
                        className="mr-2"
                      />
                    ) : (
                      <Timeline items={insuranceDetails} />
                    )}
                  </div>
                ) : Object.keys(flexpaData || [])?.length > 0 ? (
                  <FlexpaDataNotAvailableMsg flexpaData={flexpaData} />
                ) : (
                  <div
                    style={{ height: 200 }}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <span className="text-muted">
                      You can view your data after connecting with your
                      insurance provider.
                    </span>
                  </div>
                )}
              </CardBody>
            </Card> */}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default YourData;
