import { LINK_STATUS } from "../constants";
import { authActions } from "./authReducer";

export const flexPaActions = {
  LOAD_FLP_PATIENT_DATA: "LOAD_FLP_PATIENT_DATA",
  SET_FLP_PATIENT_DATA: "SET_FLP_PATIENT_DATA",
  ERROR_FLP_PATIENT_DATA: "ERROR_FLP_PATIENT_DATA",
  LOAD_FLP_LINK_STATUS: "LOAD_FLP_LINK_STATUS",
  SET_FLP_LINK_STATUS: "SET_FLP_LINK_STATUS",
};

const initState = { isLoading: false, data: null, status: LINK_STATUS.STARTED };

const reducer = (state = initState, action) => {
  switch (action.type) {
    case authActions.AUTH_LOGIN_REQ_SUCCESS:
    case authActions.AUTH_USER_SIGNOUT: {
      return initState;
    }
    case flexPaActions.LOAD_FLP_PATIENT_DATA: {
      return { ...state, isLoading: action.payload || true, data: null };
    }
    case flexPaActions.ERROR_FLP_PATIENT_DATA: {
      return initState;
    }
    case flexPaActions.SET_FLP_PATIENT_DATA: {
      return { ...state, isLoading: false, data: action.payload?.value };
    }
    default:
      return state;
  }
};

export default reducer;
