import { useCallback, useEffect, useState } from "react";
import apiClient from "./apiClient";

const usePreConfig = () => {
  const [config, setConfig] = useState();
  const fetchPreConfig = useCallback(async () => {
    try {
      localStorage.removeItem('pre-config');
      const response = await apiClient({
        method: "GET",
        url: "/app/pre-config",
      });
      localStorage.setItem("pre-config", JSON.stringify(response.data));
      setConfig(response.data);
    } catch (error) {
      console.log("Error while fetching pre-configuration.");
    }
  }, []);

  useEffect(() => {
    const preConfigStr = localStorage.getItem("pre-config");
    if (!preConfigStr) {
      fetchPreConfig();
    } else {
      setConfig(JSON.parse(preConfigStr) || null);
    }
  }, [fetchPreConfig]);

  return { preConfig: config, refreshConfig: fetchPreConfig };
};

export default usePreConfig;
