import React from "react";

const NoticeOfPrivacyPractice = () => {
  return (
    <div className="bg-white p-3 p-md-5 rounded-bottom">
      <div className="col-sm-10 py-5 mx-auto">
        <h1 className="text-center font-large">Notice of Privacy Practices</h1>
        <h3>Health X Change, Inc., Effective Date: 12/5/2023</h3>
        <div className="privacy-policy-text text-left">
          <p>
            THIS NOTICE DESCRIBES HOW MEDICAL INFORMATION ABOUT YOU MAY BE USED
            AND DISCLOSED AND HOW YOU CAN GET ACCESS TO THIS INFORMATION. PLEASE
            REVIEW IT CAREFULLY.
          </p>
          <h4>I. Our Pledge Regarding Medical Information:</h4>
          <p>
            We understand that medical information about you and your health is
            personal. We are committed to protecting medical information about
            you. We create a record of the services you receive at Health X
            Change, Inc.. We need this record to provide you with quality
            services and to comply with certain legal requirements. This notice
            applies to all of the healthcare information stored on our platform.
          </p>
          <h4>
            II. How We May Use and Disclose Medical Information About You:
          </h4>
          <p>
            At Health X Change, we take your privacy seriously and are committed
            to protecting your personal health information (PHI). Under HIPAA
            regulations, we are permitted to use and disclose your PHI for
            specific purposes, which are outlined below:
          </p>
          <p>
            <strong> Targeted Advertising and Solutions:</strong> We may use
            your health information to develop and display targeted ads and
            solutions that may be of interest to you. This may involve analyzing
            your health data to identify services or products that could benefit
            your health needs. We never share your identifying information with
            any advertisers or 3rd party companies.
          </p>
          <p>
            <strong> Population Level Insights:</strong> We may use your health
            information, in an anonymized form, to generate insights at the
            population level. This means your individual identity will not be
            disclosed. This data can be used to understand trends, patterns, and
            health issues among our user base, which can help in improving our
            services and contributing to public health knowledge.
          </p>
          <p>
            <strong> Medical Research:</strong> We are committed to advancing
            medical research. To this end, we may use your health information,
            in an anonymized form, for research purposes. This means that all
            personally identifiable information will be removed from the data
            set before it is used for research, ensuring your personal identity
            is protected. Again, this data set will never contain your
            identifiable information – but anonymized data may be shared as part
            of research agreements with 3rd parties.
          </p>
          <p>
            <strong> Important Note on Anonymization: </strong> When we use your
            information for generating population-level insights or for medical
            research, we ensure that the data is anonymized to protect your
            privacy. Anonymization is a process where identifiable information
            is removed or altered to prevent the identification of the
            individual to whom it relates.
          </p>
          <p>
            <strong> Your Rights and Choices: </strong> You have certain rights
            and choices regarding how we use and disclose your health
            information for these purposes. Please refer to the section "Your
            Rights Regarding Medical Information About You" for more details.
          </p>

          <h4>III. Special Situations:</h4>
          <p>
            There may be special situations where we may be forced to turn over
            PHI. They are listed below.
            <ul>
              <li>As Required By Law</li>
              <li>Public Health Risks</li>
              <li>Health Oversight Activities</li>
              <li>Lawsuits and Disputes</li>
              <li>Law Enforcement</li>
              <li>Coroners, Medical Examiners, and Funeral Directors</li>
              <li>National Security and Intelligence Activities</li>
              <li>Protective Services for the President and Others</li>
              <li>Inmates</li>
            </ul>
          </p>
          <h4>IV. Your Rights Regarding Medical Information About You:</h4>
          <p>
            Any of the below rights may be exercised by emailing:
            support@healthxchange.us
            <ul>
              <li>Right to Inspect and Copy</li>
              <li>Right to Amend</li>
              <li>Right to an Accounting of Disclosures</li>
              <li>Right to Request Restrictions</li>
              <li>Right to Request Confidential Communications</li>
              <li>
                Right to a Paper Copy of This Notice (a valid reason must be
                provided and a reasonable cost-based fee will be charged for
                this service)
              </li>
            </ul>
          </p>
          <h4>V. Changes to This Notice:</h4>
          <p>
            We may change our policies at any time. Changes will apply to
            medical information we already hold, as well as new information
            after the change occurs. Before we make a significant change in our
            policies, we will change our notice and post the new notice
            prominently.
          </p>
          <h4>VI. Complaints:</h4>
          <p>
            If you believe your privacy rights have been violated, you can file
            a complaint with Health X Change or with the U.S. Department of
            Health and Human Services Office for Civil Rights. You will not be
            penalized for filing a complaint.
          </p>
          <h4>VII. Contact Information:</h4>
          <p>
            For more information about our privacy practices, or for additional
            copies of this notice, please contact us through
            <a href="mailto:support@healthxchange.us">
              {" "}
              support@healthxchange.us{" "}
            </a>{" "}
            or contact our Privacy Officer, 
            <strong> Sridhar Madala</strong> at{" "}
            <a href="mailto:sri@healthxchange.us">sri@healthxchange.us</a>.
          </p>
        </div>
      </div>
    </div>
  );
};

export default NoticeOfPrivacyPractice;
