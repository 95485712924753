export const MissionContent = {
  "title": "Our Mission",
  "type": "markdown",
  "text": [
    "We saw hospitals, insurers, tech companies, and pharma companies all benefit from the explosion of health data. The only people not making money… patients!!! At Health X Change, we want to create a new system to fairly compensate patients while pushing forward cutting edge research and medical breakthroughs!",
    <span>Companies sell &nbsp;YOUR health data, we want to get you PAID for it!</span>
  ],
  "titleColor": "text-white",
  "textColor": "text-white"
}
