import authReducer from "./authReducer";
import flexPaReducer from "./flexpaReducer";
import uiReducer from "./uiReducer";
import linkReducer from "./linkStatusReducer";
import refReducer from "./refReducer";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";

const rootReducer = combineReducers({
  auth: authReducer,
  flexpa: flexPaReducer,
  ui: uiReducer,
  refs: refReducer,
  flexpaLink: linkReducer,
});

export default function configureAppStore(preloadedState) {
  const store = configureStore({
    reducer: rootReducer,
    middleware: [thunk],
    preloadedState,
  });

  return store;
}
