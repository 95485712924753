import { Link } from "react-router-dom";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
  Label
} from "reactstrap";
import useAuth from "../../utils/useAuth";

const AdminNavbar = (props) => {
  const { auth = {}, dispatchSignOut } = useAuth();
  const { username, picture } = auth;
  return (
    <>
      <Navbar className="navbar-top bg-gradient-info p-0 p-md-3" expand="md" id="navbar-main">
        <Container fluid>
          <Label
            className="h4 mb-0 text-white text-uppercase d-none d-md-inline-block"
          >
            {props.brandText}
          </Label>
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0 d-flex align-items-center text-white" nav caret>
                <Media className="align-items-center">
                  <span className="avatar rounded-circle position-absolute" style={{ marginLeft: -30, height: 30, width: 30 }}>
                    {picture ? (
                      <img
                        alt="..."
                        src={
                          picture
                            ? picture
                            : require("../../assets/img/theme/team-4-800x800.jpg")
                        }
                      />
                    ) : (
                      <i className="fas fa-user fa-lg" />
                    )}
                  </span>
                  <Media className="ml-2 d-none d-md-block">
                    <span className="mb-0 text-sm text-white font-weight-bold">
                      {username}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Welcome!</h6>
                </DropdownItem>
                <DropdownItem to="/admin/profile" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>My Profile</span>
                </DropdownItem>
                <DropdownItem to="/privacy" tag={Link}>
                  <i className="ni ni-settings-gear-65" />
                  <span>Security & Privacy</span>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem
                  href="#pablo"
                  onClick={(e) => {
                    e.preventDefault();
                    dispatchSignOut();
                  }}
                >
                  <i className="ni ni-user-run" />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
