import React, { lazy, useEffect } from "react";
import { useLocation, Redirect, useParams } from "react-router-dom";
import MiddleBlockContent from "../components/Homepage/content/MiddleBlockContent.json";
import { MissionContent } from "../components/Homepage/content/MissionContent.js";
import { verifyEmail } from "../actions/authActions";

const Container = lazy(() => import("../components/Homepage/common/Container"));
const ScrollToTop = lazy(() => import("../components/Homepage/common/ScrollToTop"));
const ContentBlock = lazy(() => import("../components/Homepage/components/ContentBlock"));

const Home = (props) => {

  const location = useLocation();
  const { email, token } = useParams();
  const urlSearchParams = new URLSearchParams(location.search);
  const success = urlSearchParams.get('success');
  const iss = urlSearchParams.get('iss');
  const code = urlSearchParams.get('code');
  const appState = urlSearchParams.get('state');

  // const [confirmationResponse, setConfirmation] = useState('');

  useEffect(() => {
    const verify = async (email, token) => {
      const response = await verifyEmail(email, token);    
      alert(response);  
      // setConfirmation(response);
    }
    if (props.intent === "confirmation" && email && token) {
      verify(email, token)
    }
  }, [props.intent, email, token]);

  React.useEffect(() => {
    document.body.classList.add("bg-white");
    return () => {
      document.body.classList.remove("bg-white");
    };
  }, []);
  
  if (appState === "demo_setup" && code) {
    return <Redirect to={`/admin/demo?code=${code}&state=${appState}`} />
  } else if (success === "true" && iss) {
    return <Redirect to='/data-import' />;
  }
  
  return (
    <Container>
      <ScrollToTop />
      <ContentBlock
        type="left"
        title={MiddleBlockContent.title}
        content={MiddleBlockContent.text}
        titleColor={MiddleBlockContent.titleColor}
        textColor={MiddleBlockContent.textColor}
        icon="about.svg"
        id="about"
      />
      <ContentBlock
        type="right"
        titleColor={MissionContent.titleColor}
        textColor={MissionContent.textColor}
        title={MissionContent.title}
        content={MissionContent.text}
        icon="mission.svg"
        id="mission"
      />
    </Container>
  );
};

export default Home;
