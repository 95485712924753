import React from "react";

const TermsConditions = () => {
  return (
    <div className="bg-white p-3 p-md-5 rounded-bottom">
      <div className="col-sm-10 py-5 mx-auto">
        <h1 className="text-center font-large">Terms & Conditions</h1>
        <div className="privacy-policy-text text-left">
          <p>Acceptance of the Terms of Use.</p>
          <p>
            By accessing or using the Health X Change, Inc. platform, website
            and/or the mobile application, offered by Health X Change, Inc.
            (“Health X Change,” “we,” “us” or “our”) at{" "}
            <a
              data-auto-recognition="true"
              href="http://www.health-x-change.com"
              target="_blank"
              rel="noreferrer"
            >
              www.health-x-change.com
            </a>{" "}
            and all associated pages and services (collectively referred to as
            our “Website”) and/or through the mobile application that you have
            downloaded, including all software therein (the “Mobile
            Application”), the Mobile Application and Website collectively
            referred to as the “Services”, you (the “User”, “your” or “you”)
            confirm that you have read, understand and agree to be bound by
            these terms of use (“Terms of Use”). Please read the Terms of Use
            carefully and print a copy for your records.
          </p>

          <p>
            IF YOU ARE UNWILLING TO BE BOUND BY THESE TERMS OF USE, DO NOT
            ACCESS OR USE THE SERVICES.
          </p>

          <p>
            PLEASE BE AWARE THAT THESE TERMS OF SERVICE, BELOW, CONTAINS
            PROVISIONS THAT GOVERN HOW CLAIMS THAT YOU AND HEALTH X CHANGE, INC.
            HAVE AGAINST EACH OTHER ARE RESOLVED. IN PARTICULAR, IT CONTAINS AN
            ARBITRATION AGREEMENT WHICH WILL, WITH LIMITED EXCEPTIONS, REQUIRE
            YOU TO SUBMIT CLAIMS THAT YOU HAVE AGAINST HEALTH X CHANGE, INC. TO
            BINDING AND FINAL ARBITRATION. UNLESS YOU OPT OUT OF THE ARBITRATION
            AGREEMENT IN SECTION 15 OF THESE TERMS: (i) YOU WILL ONLY BE
            PERMITTED TO PURSUE CLAIMS AND SEEK RELIEF AGAINST HEALTH X CHANGE,
            INC. ON AN INDIVIDUAL BASIS, NOT AS A PLAINTIFF OR CLASS MEMBER IN
            ANY CLASS OR REPRESENTATIVE ACTION OR PROCEEDING; AND (ii) YOU ARE
            WAIVING YOUR RIGHT TO SEEK RELIEF IN A COURT OF LAW AND TO HAVE A
            JURY TRIAL ON YOUR CLAIMS. THE ARBITRATION PROVISIONS SET FORTH IN
            THESE TERMS COULD AFFECT YOUR RIGHT TO PARTICIPATE IN PENDING CLASS
            ACTION LITIGATION.
          </p>

          <p>
            PLEASE SEE BELOW FOR MORE INFORMATION REGARDING THESE ARBITRATION
            PROVISIONS, INCLUDING THE ARBITRATION PROVISIONS’ IMPACT ON THE
            PENDING CLASS LITIGATION AND HOW TO OPT OUT OF ARBITRATION. THE
            TERMS OF SERVICE LIMIT THE REMEDIES THAT MAY BE AVAILABLE TO YOU IN
            THE EVENT OF A DISPUTE.
          </p>

          <ol>
            <li>
              <p>
                Changes to these Terms of Use.
                <br />
                By accessing our Services, you acknowledge that we have the
                right to revise and amend
                <br />
                these Terms of Use without prior notice. Your continued use of
                the Service following our posting of any such changes will mean
                that you accept such changes. Notwithstanding the foregoing, We
                may notify you at the email address provided to us or by a
                posting in the Mobile Application or Website in the event that
                we make any material changes to these Terms of Use, and you may
                have to agree to or reject the updated Terms of Use at that
                time, in order to continue using the Services.
              </p>
            </li>
          </ol>

          <p>
            Mobile Application. If the User has elected to download our Mobile
            Application, we hereby grant you a limited, non-transferable,
            revocable license to use the object code of such software within the
            Mobile Application on any mobile device that you own or control that
            such Mobile Application is authorized to be operated on (as
            determined by us in our sole discretion) and is permitted by this
            Terms of Use. The Mobile Application is licensed, not sold, to you
            for use only under the terms of this Terms of Use. Health X Change,
            Inc. reserves all rights, title and interest not expressly granted
            to you. Nothing herein allows you to use the Mobile Application on a
            device that you do not own or are not authorized to control.
            Furthermore, with respect to any Mobile Application accessed through
            or downloaded from an App Store such as Google Play® store or the
            Apple’s® App Store® (an “App Store Sourced Application”), you will
            only use the App Store Sourced Application: (1) on a product that
            runs the operating system for which it was intended and (2) as
            permitted by the “Usage Rules” set forth in the corresponding App
            Store. Use of the Mobile Application from a third party App Store is
            also subject to the provisions outlined here (App Store).
          </p>

          <ol>
            <li>
              <p>
                The Services are For Use by Individuals 18 Years of Age and
                Older. While Health X Change, Inc. stores, processes and
                transfers data of individuals of all age, including data
                regarding children based on their guardian’s or parent’s
                consent, the Services are intended solely to be accessed by
                natural persons who are eighteen (18) years of age or older, and
                any registration by, use of, or access to the Services by any
                person under 18 is unauthorized and in violation of these Terms
                of Use. We may terminate your use of the Services without notice
                if we believe you are less than 18 years old. By using the
                Services, you represent and warrant that, you are a natural
                person, you are 18 or older, and that you agree to and will
                abide by all of the terms and conditions of these Terms of Use.
              </p>
            </li>
            <li>
              <p>
                Services Use Restrictions. Without our prior written consent,
                you may not:
              </p>
            </li>
          </ol>

          <ul>
            <li>
              <p>
                Use any automated means to access this Services or collect any
                information from the Services (including, without limitation,
                robots, spiders, scripts, or other automatic devices or
                programs);
              </p>
            </li>
            <li>
              <p>
                Frame the Services in any manner, utilize framing techniques to
                enclose any content or other proprietary information, place
                pop-up windows over any Services’ pages, or otherwise affect the
                display of any pages on the Services;
              </p>
            </li>
            <li>
              <p>
                Engage in the practices of “screen scraping,” “database
                scraping” or any other activity with the purpose of obtaining
                content or other information;
              </p>
            </li>
            <li>
              <p>
                Use the Services in any manner that violates applicable law or
                that could alter, damage, disable, overburden, or impair the
                Services or interfere with any other party’s use and enjoyment
                of the Services; or
              </p>
            </li>
            <li>
              <p>
                Access, use or monitor our Services for benchmarking or any
                competitive purposes. We may terminate or disable your access to
                these Services for any reason, with or without cause, including
                if we believe that you have violated or acted inconsistently
                with these Terms of Use.
              </p>
            </li>
          </ul>

          <p>User Representation, Content, and Consent</p>

          <ul>
            <li>
              <p>
                User Representations. Parts of the Services may be accessed only
                by registering for an account and creating a password. Keep your
                password secure. You are responsible for the activities on your
                account. You represent, warrant, and agree that no materials of
                any kind submitted through your account or otherwise posted or
                shared by you through the Services will violate or infringe upon
                the rights of any third party, including without limitation any
                copyright, trademark, patent, privacy, publicity, or other
                personal or intellectual property rights; or contain libelous,
                defamatory, or otherwise unlawful material. You will notify us
                promptly if you discover any unauthorized use of your account.
                We are not responsible for any losses resulting from
                unauthorized use of your account. In addition, you agree not to
                use the Services to:
              </p>
            </li>
          </ul>

          <ol>
            <li>
              <p>
                Except where authorized by us, register for more than one User
                account, register or operate a User account on behalf of or for
                the benefit of any person who is not eligible to register for or
                operate a User account in their own name;
              </p>
            </li>
            <li>
              <p>
                Impersonate any person or entity, or falsely state or otherwise
                misrepresent yourself, your age, or your affiliation with or
                authority to act on behalf of any person or entity;
              </p>
            </li>
            <li>
              <p>
                Upload, post, transmit, share, store, or otherwise make publicly
                available through the Services any private information of any
                third party, including, without limitation, addresses, phone
                numbers, email addresses, Social Security numbers, and credit
                card numbers, unless expressly authorized to do so by that third
                party;
              </p>
            </li>
            <li>
              <p>
                Upload, post, transmit, share, or otherwise make available any
                material that contains software viruses or any other computer
                code, files, or programs designed to interrupt, destroy, or
                limit the functionality of the Services; or Use or attempt to
                use another’s account without authorization from that person, or
                create a false identity through the Services.
              </p>
            </li>
            <li>
              <p>
                The Services Do Not Provide Medical Advice The Services are
                provided for your convenience. They contain a Website for a
                secure, private view into electronic health records and may give
                you a secure way augment ways to communicate with your care team
                of anticipated health needs. It may also provide you with tools
                to help you manage your health. Use of the Services does not
                create a health care provider/patient relationship between you
                and Health X Change, Inc. or any of our providers. The Services
                are not a substitute for professional medical advice, diagnosis
                or treatment. You should always seek the advice of a physician
                or other qualified health care provider with any questions
                regarding personal health or medical conditions. Never
                disregard, avoid or delay in obtaining medical advice from your
                doctor or other qualified health care provider because of
                something you have read through the Services. If you suspect
                that you have a medical problem or condition, please contact a
                qualified health care professional immediately. IF YOU ARE
                EXPERIENCING AN EMERGENCY, PLEASE DIAL 911.
              </p>
            </li>
            <li>
              <p>
                Information Posted through the Services. As between us and you,
                all content made available on or through the Services, whether
                uploaded, published, or displayed by us, including designs,
                text, graphics, pictures, video, information, software, music,
                sound and other files, and their selection and arrangement,
                except as provided in this document (App Store) is the property
                of Health X Change, Inc. (collectively the “Health X Change,
                Inc. Content”). To the best of our knowledge, we use only
                content that we own or have permission to use. No Health X
                Change, Inc. Content may be modified, copied, distributed,
                framed, reproduced, republished, downloaded, displayed, posted,
                transmitted, or sold in any form or by any means, in whole or in
                part, without the owner’s prior written permission. Unless
                explicitly stated herein, nothing in these Terms of Use shall be
                construed as conferring any license to intellectual property
                rights, whether by estoppel, implication, or otherwise. You are
                solely responsible for the information that you post on or
                through the Services and your conduct regarding the Services. By
                posting information to or through the Services, you agree to (a)
                provide accurate, current, and complete information; (b)
                maintain the security of your password and identification, to
                the extent you are provided a password and identification; (c)
                promptly notify us of any changes to information or
                circumstances that could affect your eligibility to continue
                using the Services; and (d) be fully responsible for all use of
                your account and for any actions that take place using your
                account. The Services may contain links to third-party websites
                and services, and/or display advertisements for third parties
                (collectively, “Third-Party Links &amp; Ads”). Where the
                Services contain links to Third-Party Links &amp; Ads, these
                links are provided for your information and convenience only. We
                have no control over the contents of those sites or resources.
                Health X Change, Inc. does not review, approve, endorse or make
                any promises with respect to Third-Party Links &amp; Ads. You
                use Third-Party Links &amp; Ads at your own risk. You use all
                Third-Party Links &amp; Ads at your own risk, and should apply a
                suitable level of caution and discretion in doing so. When you
                click on any of the Third-Party Links &amp; Ads, the applicable
                third party’s terms and policies apply, not these Terms of Use.
              </p>
            </li>
            <li>
              <p>
                Consent to be Contacted. To the extent required by applicable
                law, we ensure we make the proper disclosures and obtain
                consumer consent when collecting your contact information.
              </p>
            </li>
            <li>
              <p>
                Intellectual Property. Trademarks The “Health X Change, Inc.”
                name and all associated graphics, logos, designs, page headers,
                button icons, scripts, and service names are registered
                trademarks, trademarks, or trade dress in the United States.
                Health X Change, Inc.’s trademarks and trade dress may not be
                used, including as part of trademarks or as part of domain
                names, in connection with any product or service in any manner
                that is likely to cause confusion and may not be copied,
                imitated, or used, in whole or in part, without the prior
                written permission of Health X Change, Inc.. Submissions You
                acknowledge and agree that any questions, comments, suggestions,
                ideas, feedback, or other information (“Submissions”), provided
                by you to us through the Services are non-confidential and shall
                become the sole property of Health X Change, Inc.. Health X
                Change, Inc. shall own exclusive rights, including all
                intellectual property rights, and shall be entitled to the
                unrestricted use and dissemination of these Submissions for any
                purpose, commercial or otherwise, without acknowledgment or
                compensation to you. Copyright Complaints If you believe that
                any material on the Services infringes upon any copyright which
                you own or control, you may send a written notification of such
                infringement to our designated agent as set forth below: Health
                X Change, Inc. ●{" "}
                <a
                  data-auto-recognition="true"
                  href="mailto:admin@health-x-change.com"
                >
                  admin@health-x-change.com
                </a>{" "}
                To meet the notice requirements under the Digital Millennium
                Copyright Act, the notification must be a written communication
                including the following: A physical or electronic signature of a
                person authorized to act on behalf of the owner of an exclusive
                right that is allegedly infringed; Identification of the
                copyrighted work claimed to have been infringed, or, if multiple
                copyrighted works, a representative list of such works at that
                site; Identification of the material that is claimed to be
                infringing or to be the subject of infringing activity and that
                is to be removed or access to which is to be disabled, and
                information reasonably sufficient to permit us to locate the
                material; Information reasonably sufficient to permit us to
                contact the complaining party, such as an address, telephone
                number, and, if available, an electronic mail address at which
                the complaining party may be contacted; A statement that the
                complaining party has a good-faith belief that use of the
                material in the manner complained of is not authorized by the
                copyright owner; and A statement that the information in the
                notification is accurate, and under penalty of perjury, that the
                complaining party is authorized to act on behalf of the owner of
                an exclusive right that is allegedly infringed.
                <br />
                App Store. When you download our Apps, you may do so through a
                third party’s online application store (“App Store”). You
                acknowledge that these Terms of Use are between you and us and
                not with the owner or operator of the App Store (“App Store
                Owner”). As between the App Store Owner and us, we, and not the
                App Store Owner, are solely responsible for the Services,
                including the App, the content, maintenance, support services,
                and warranty, and addressing any claims relating thereto (e.g.,
                product liability, legal compliance or intellectual property
                infringement). In order to use the App, you must have access to
                a wireless or cellular network, and you agree to pay all fees
                associated with such access. You also agree to pay all fees (if
                any) charged by the App Store Owner in connection with the
                Services, including the App. The following applies to any App
                Store Sourced Application: Your use of the App Store Sourced
                Application must comply with the App Store’s “Terms of Service”
                or equivalent terms. You acknowledge that the App Store Owner
                has no obligation whatsoever to furnish any maintenance and
                support services with respect to the App Store Sourced
                Application. In the event of any failure of the App Store
                Sourced Application to conform to any applicable warranty, you
                may notify the App Store Owner, and the App Store Owner will
                refund the purchase price for the App Store Sourced Application
                to you (if any) and to the maximum extent permitted by
                applicable law, the App Store Owner will have no other warranty
                obligation whatsoever with respect to the App Store Sourced
                Application. As between Health X Change, Inc. and the App Store
                Owner, any other claims, losses, liabilities, damages, costs or
                expenses attributable to any failure to conform to any warranty
                will be the sole responsibility of Health X Change, Inc.. You
                and we acknowledge that, as between Health X Change, Inc. and
                the App Store Owner, the App Store Owner is not responsible for
                addressing any claims you have or any claims of any third party
                relating to the App Store Sourced Application or your possession
                and use of the App Store Sourced Application, including, but not
                limited to: (1) product liability claims; (2) any claim that the
                App Store Sourced Application fails to conform to any applicable
                legal or regulatory requirement; and (3) claims arising under
                consumer protection or similar legislation.
                <br />
                You and we acknowledge that, in the event of any third-party
                claim that the App Store Sourced Application or your possession
                and use of that App Store Sourced Application infringes that
                third party’s intellectual property rights, as between Health X
                Change, Inc. and the App Store Owner, Health X Change, Inc., not
                the App Store Owner, will be solely responsible for the
                investigation, defense, settlement and discharge of any such
                intellectual property infringement claim to the extent required
                by these Terms of Use. You and we acknowledge and agree that the
                App Store Owner, and the App Store Owner’s subsidiaries, are
                third-party beneficiaries of these Terms of Use as related to
                your license of the App Store Sourced Application, and that,
                upon your acceptance of these Terms of Use, the App Store Owner
                will have the right (and will be deemed to have accepted the
                right) to enforce the terms of these Terms of Use as related to
                your license of the App Store Sourced Application against you as
                a third-party beneficiary thereof. You represent and warrant
                that (1) you are not located in a country that is subject to a
                U.S. Government embargo, or that has been designated by the U.S.
                Government as a “terrorist supporting” country; and (2) you are
                not listed on any U.S. Government list of prohibited or
                restricted parties. Without limiting any other terms in these
                Terms of Use, you must comply with all applicable third-party
                terms of agreement when using the App Store Sourced Application.
              </p>
            </li>
          </ol>

          <p>DISCLAIMER AND LIABILITY</p>

          <p>
            Disclaimers. We reserve the right to change any and all content
            within the Services and any service offered through the Services at
            any time without notice. We provide the Services “AS IS” and assume
            no responsibility for any failure to provide the Services to you.
            The Services may be temporarily unavailable from time to time for
            maintenance or other reasons. We may discontinue the Services or any
            goods or services available through the Services at any time and for
            any reason. We are not responsible for any problems or technical
            malfunction of any telephone or cable network or lines, servers or
            providers, computer equipment, software, failure of email, or
            technical problems or traffic congestion on the Internet or on or
            through the Services, including injury or damage to Users or to any
            other person’s devices related to or resulting from use of the
            Services. Under no circumstances will we be responsible for any loss
            or damage, including any loss or damage to any user data, financial
            damages, lost profits, loss of business, or personal injury or
            death, resulting from anyone’s use of the Services. YOU AGREE THAT
            YOUR USE OF THE SERVICES IS AT YOUR OWN RISK, and that we EXPRESSLY
            DISCLAIM ALL WARRANTIES, TERMS AND CONDITIONS, INCLUDING, BUT NOT
            LIMITED TO, THE IMPLIED WARRANTIES, TERMS AND CONDITIONS OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NONINFRINGEMENT
            OF THIRD PARTY RIGHTS AND SATISFACTORY QUALITY.
            <br />
            Limitation of Certain Damage Types. EXCEPT IN JURISDICTIONS WHERE
            SUCH PROVISIONS ARE RESTRICTED OR PROHIBITED, IN NO EVENT WILL
            HEALTH X CHANGE, INC. OR ANY OF ITS DIRECTORS, EMPLOYEES, OR AGENTS
            BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY INDIRECT, CONSEQUENTIAL,
            EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING FOR
            ANY LOST PROFITS OR LOST DATA ARISING FROM YOUR USE OF THE SERVICES
            OR ANY OF CONTENT OR OTHER MATERIALS ON OR ACCESSED THROUGH THE
            SERVICES, EVEN IF ANY OF US IS AWARE OF OR HAS BEEN ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGES.
            <br />
            Limitation of Liability Amount. TO THE EXTENT NOT PROHIBITED BY
            APPLICABLE LAW, OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER, AND
            REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED
            TO THE GREATER OF THE AMOUNT PAID BY YOU TO US FOR THE SERVICES OR
            PRODUCT AT ISSUE IN THE THREE MONTHS PRIOR TO THE EVENT GIVING RISE
            TO THE CLAIM AND $20. YOU ACKNOWLEDGE THAT IF NO FEES ARE PAID TO US
            FOR A SERVICE OR PRODUCT, YOU SHALL BE LIMITED TO, AT MOST,
            INJUNCTIVE RELIEF ONLY, UNLESS OTHERWISE PROHIBITED BY APPLICABLE
            LAW, AND SHALL NOT BE ENTITLED TO ANY OTHER DAMAGES, REGARDLESS OF
            THE CAUSE OF ACTION. NOTHING IN THESE TERMS SHALL LIMIT OR EXCLUDE
            OUR LIABILITY FOR: (i) DEATH OR PERSONAL INJURY RESULTING FROM OUR
            WILLFUL MISCONDUCT; (ii) FRAUD OR FRAUDULENT MISREPRESENTATIONS; OR
            (iii) ANY OTHER LIABILITY THAT CANNOT BE EXCLUDED BY APPLICABLE LAW.
            <br />
            Disputes, Governing Law, Venue, and Jurisdiction. By using the
            Services, you agree that these Terms of Use shall be governed by the
            laws of the State of Delaware without regard to its conflict of law
            provisions. For any cause of action initiated against Health X
            Change, Inc. relating to these Terms of Use, you and Health X
            Change, Inc. agree to submit to the exclusive and personal
            jurisdiction of the courts located in Dover, Delaware. Our failure
            to exercise or enforce any right or provision of these Terms of Use
            shall not constitute a waiver of that right or provision. If any
            provision of these Terms of Use is found by a court of competent
            jurisdiction to be invalid, then we nevertheless agree that the
            court should endeavor to give effect to the intentions reflected in
            the provision, and the other provisions of these Terms of Use shall
            remain in full force and effect. The language of these Terms of Use
            shall be construed as to its fair meaning and not strictly for or
            against any party.
            <br />
            Arbitration Agreement &amp; Dispute Resolution Please read this
            Arbitration Agreement carefully. It is part of your contract with
            Health X Change, Inc. and affects your rights. It contains
            procedures for MANDATORY BINDING ARBITRATION AND A CLASS ACTION
            WAIVER.
            <br />
            Scope of Arbitration Agreement (“Arbitration Agreement”). You
            acknowledge and agree that any dispute or claim relating in any way
            to your access or use of the Services or to any other aspect of your
            relationship with Health X Change, Inc. will be resolved by binding
            arbitration, rather than in court, except that (1) you may assert
            claims in small claims court if your claims qualify, so long as the
            matter remains in such court and advances only on an individual
            (non-class, non-representative) basis; and (2) you or Health X
            Change, Inc. may seek equitable relief in court for infringement or
            other misuse of intellectual property rights (such as trademarks,
            trade dress, domain names, trade secrets, copyrights, and patents).
            This Arbitration Agreement shall apply, without limitation, to all
            claims that arose before this or any prior agreement. NOW OR IN THE
            FUTURE, THERE MAY BE LAWSUITS AGAINST Health X Change, Inc. ALLEGING
            CLASS, COLLECTIVE, AND/OR REPRESENTATIVE CLAIMS. SUCH CLAIMS, IF
            SUCCESSFUL, COULD RESULT IN SOME MONETARY RECOVERY TO YOU. THE
            EXISTENCE OF SUCH CLASS, COLLECTIVE, AND/OR REPRESENTATIVE LAWSUITS
            DOES NOT MEAN THAT SUCH LAWSUITS WILL ULTIMATELY SUCCEED. BUT IF YOU
            AGREE TO ARBITRATION WITH HEALTH X CHANGE, INC., YOU ARE AGREEING IN
            ADVANCE THAT YOU WILL NOT PARTICIPATE IN OR SEEK TO RECOVERY
            MONETARY OR OTHER RELIEF UNDER SUCH CLASS, COLLECTIVE, AND/OR
            REPRESENTATIVE LAWSUITS. INSTEAD, BY AGREEING TO ARBITRATION, YOU
            MAY BRING YOUR CLAIMS AGAINST HEALTH X CHANGE, INC. IN AN INDIVIDUAL
            ARBITRATION PROCEEDING. IF SUCCESSFUL ON SUCH CLAIMS, YOU COULD BE
            AWARDED MONEY OR OTHER RELIEF BY AN ARBITRATOR.
            <br />
            Arbitration Rules and Forum. This Arbitration Agreement is governed
            by the Federal Arbitration Act in all respects. To begin an
            arbitration proceeding, you must send a letter requesting
            arbitration and describing your claim in an email to{" "}
            <span style={{ textDecoration: "underline" }}>
              <a href="mailto:admin@health-x-change.com" target="_self">
                admin@health-x-change.com
              </a>
            </span>
            . The arbitration will be conducted by the American Arbitration
            Association (“AAA”) under the AAA Consumer Arbitration Rules. The
            AAA’s rules are available at{" "}
            <a
              data-auto-recognition="true"
              href="http://www.adr.org"
              target="_blank"
              rel="noreferrer"
            >
              www.adr.org
            </a>{" "}
            or by calling the AAA at 1-800-778-7879. Payment of all filing,
            administration, and arbitration fees will be governed by the AAA’s
            rules. We will reimburse those fees for claims totaling less than
            $10,000 unless the arbitrator determines the claims are frivolous.
            Likewise, Health X Change, Inc. will not seek attorneys’ fees and
            costs in arbitration unless the arbitrator determines the claims are
            frivolous. If the AAA is not available to arbitrate, the parties
            will select an alternative arbitral forum. Arbitrator Powers. The
            arbitrator, and not any federal, state, or local court or agency,
            shall have exclusive authority to resolve any dispute relating to
            the interpretation, applicability, enforceability or formation of
            this Arbitration Agreement including, but not limited to any claim
            that all or any part of this Arbitration Agreement is void or
            voidable. The arbitration will decide the rights and liabilities, if
            any, of you and Health X Change, Inc.. The dispute will not be
            consolidated with any other matters or joined with any other cases
            or parties. The arbitrator will have the authority to grant motions
            dispositive of all or part of any claim or dispute. The arbitrator
            will have the authority to award monetary damages and to grant any
            non-monetary remedy or relief available to an individual under
            applicable law, the arbitral forum’s rules, and these Terms of Use.
            The arbitrator will issue a written award and statement of decision
            describing the essential findings and conclusions on which the award
            is based, including the calculation of any damages awarded.
            <br />
            The arbitrator has the same authority to award relief on an
            individual basis that a judge in a court of law would have. The
            award of the arbitrator is final and binding on you and Health X
            Change, Inc..
            <br />
            Waiver of Jury Trial. YOU AND HEALTH X CHANGE, INC. EACH KNOWINGLY
            AND VOLUNTARILY WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO SUE
            IN COURT AND RECEIVE A JUDGE OR JURY TRIAL. You and Health X Change,
            Inc. are instead electing to have claims and disputes resolved by
            arbitration. An arbitrator can award on an individual basis the same
            damages and relief as a court and must follow these Terms of Use as
            a court would. However, there is no judge or jury in arbitration,
            and court review of an arbitration award is limited. In any
            litigation between you and Health X Change, Inc. over whether to
            vacate or enforce an arbitration award, you and Health X Change,
            Inc. waive all rights to a jury trial, and elect instead to have a
            judge resolve the dispute.
            <br />
            Waiver of Class or Consolidated Actions. ALL CLAIMS AND DISPUTES
            WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE ARBITRATED ON
            AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS. CLAIMS OF MORE THAN
            ONE CUSTOMER OR USER CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR
            CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR USER. If, however,
            this waiver of class or consolidated actions is deemed invalid or
            unenforceable, neither you nor Health X Change, Inc. is entitled to
            arbitration. Instead, all claims and disputes will then be resolved
            in a court as set forth in this document (Disputes, Governing Law,
            Venue and Jurisdiction) above.
            <br />
            Opt Out. You may opt out of this Arbitration Agreement. If you do
            so, neither you nor Health X Change, Inc. can force the other to
            arbitrate. To opt out, you must notify Health X Change, Inc. in
            writing no later than 30 days after first becoming subject to this
            Arbitration Agreement. Your notice must include your name and
            address, your Health X Change, Inc. username (if any), the email
            address you used to set up your Health X Change, Inc. account (if
            you have one), and an unequivocal statement that you want to opt out
            of this Arbitration Agreement. You must send your opt-out notice to:{" "}
            <span style={{ textDecoration: "underline" }}>
              <a href="mailto:admin@health-x-change.com" target="_self">
                admin@health-x-change.com
              </a>
            </span>
            . If you opt out of this Arbitration Agreement, all other parts of
            these Terms of Use will continue to apply to you. Opting out of this
            Arbitration Agreement has no effect on any previous, other, or
            future arbitration agreements that you may have with us.
            <br />
            Exclusive Venue. To the extent the parties are permitted under these
            Terms of Use to initiate litigation in a court, both you and Health
            X Change, Inc. agree that all claims and disputes HEALTH X CHANGE,
            INC. TERMS OF USE arising out of or relating to the Agreement will
            be litigated exclusively in the state or federal courts located in
            Delaware.
            <br />
            Indemnity. To the maximum extent permitted by law, you agree to
            indemnify and hold us, our subsidiaries and affiliates, and each of
            their directors, officers, agents, contractors, partners, and
            employees, harmless from and against any loss, liability, claim,
            demand, damages, costs (including attorneys’ fees), and expenses,
            arising out of or in connection with your use of the Services or any
            violation of these Terms of Use. PLEASE PRINT A COPY OF THIS
            AGREEMENT FOR YOUR RECORDS AND PLEASE CHECK BACK FREQUENTLY FOR ANY
            CHANGES TO THIS AGREEMENT.
          </p>

          <p>
            Survivalship: The following Sections survive the termination of
            these Terms of Use: Disputes, Governing Law, Venue, and
            Jurisdiction, Disclaimers and Liability, Intellectual Property
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsConditions;
