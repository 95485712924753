import apiClient, { setReqHeader } from "./apiClient";

export const preLoadState = async () => {
  const preState = {};
  const user = JSON.parse(localStorage.getItem("user"));
  const refreshToken = localStorage.getItem("refreshToken");
  if (refreshToken) {
    try {
      const response = await apiClient({
        method: "POST",
        url: "/auth/token",
        data: { refreshToken },
      });
      const { authToken } = response.data;
      setReqHeader('Authorization', authToken);
      if (user) {
        preState.auth = user;
      }
    } catch (error) {
      localStorage.removeItem("user");
      localStorage.removeItem("refreshToken");
    }
  }
  return preState;
};
