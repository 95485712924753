import { LINK_STATUS } from "../constants";
import apiClient from "../utils/apiClient";

export const getPatientData = async (publicToken) => {
  try {
    const response = await apiClient({
      method: "post",
      url: "/flexpa/data-sync",
      data: { publicToken },
    });
    return response.data;
  } catch (error) {
    console.log("ERROR while fetching Patient bulk data.", error.message);
    throw error;
  }
};

export const getFlpLinkStatus = async () => {
  try {
    const response = await apiClient({
      method: 'get',
      url: '/flexpa/link-status'
    });
    return response.data;
  } catch (error) {
    return { status: LINK_STATUS.TRY_AGAIN, flexpaData: null };
  }
}

export const getExistingPatientData = async () => {
  try {
    const response = await apiClient({
      method: "get",
      url: "/flexpa/all-data"
    });
    return response.data;
  } catch (error) {
    console.log("ERROR while fetching Patient bulk data.", error.message);
    return null;
  }
};